import { PDFViewer } from '@react-pdf/renderer'
import {
  Grid,
  Typography,
  Button,
  Fab,
  TextField,
  ButtonGroup,
} from '@mui/material'
//import UserAgreement from '../UserAgreement.pdf';

function Privacy() {
  return (
    <Grid
      container
      justify="center"
      style={{
        height: '100vh',
      }}
    >
      <object
        data="/disclaimer.pdf"
        type="application/pdf"
        width="100%"
        height="100%"
      />
    </Grid>
  )
}

export default Privacy
