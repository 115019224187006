import { Auth } from 'aws-amplify'
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAuthModalOpen,
  setSubscribeModalOpen,
} from '../redux/slices/uiSlice'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '../assets/icons/CloseIcon'
import { useEffect, useState } from 'react'
import { Refresh, Visibility, VisibilityOff } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import axios from 'axios'
import StripeForm from '../form/StripeForm'
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { CreateSetupIntentUrl, CreatePaymentIntentUrl } from '../constants/urls'
import BackChevronIvon from '../assets/icons/BackChevronIcon'
import { setCognitoUser } from '../redux/slices/authSlice'

export default function SubscribeModal() {
  const dispatch = useDispatch()
  const open = useSelector((state) => state.ui.subscribeModalOpen)
  const cognitoUser = useSelector((state) => state.auth.cognitoUser)
  const subscription = useSelector((state) => state.ui.subscription)
  // const [open, setOpen] = useState(false)
  const [page, setPage] = useState(null)
  const [clientSecret, setClientSecret] = useState(null)
  // const STRIPE_KEY =
  //   'pk_test_51MjoT4HQsb6NHf28PbaQcjPn0ETxMgdI3A9zvzFQyag1x4G1TpBJhWpPVNjz9d3ca523lKp5J6xmdBR3fQMoFBkV00igu8rxPZ'
  const STRIPE_KEY =
    'pk_live_51MjoT4HQsb6NHf28exjSXcnEZMsg3Tu76Gcb6ZwoKIymgEHdBS1FlOtmGfndwpoxtjfGmr7Rl5Mht6kfnpb0rlsC008V8oF3WE'
  const stripePromise = loadStripe(STRIPE_KEY)
  // const handleClose = () => {
  //   dispatch(setSubscribeModalOpen(false))
  // }
  const fetchClientSecret = async () => {
    try {
      // const response = await axios.post(CreateSetupIntentUrl, {
      //   customerId: cognitoUser['custom:stripe_customer_id'],
      // })
      const response = await axios.post(CreatePaymentIntentUrl, {
        customerId: cognitoUser['custom:stripe_customer_id'],
      })
      //console.log('response: ', response)
      //const setupId = response.data.id
      //console.log('setupId: ', setupId)
      const secret = response.data.client_secret
      //console.log('Secret: ', secret)
      //setOptions({ clientSecret: secret })
      setClientSecret(secret)
      setPage(1)
    } catch (error) {
      console.error('ERROR: ', error)
    }
  }

  useEffect(() => {
    if (cognitoUser?.['custom:stripe_customer_id']) {
      fetchClientSecret()
    }
  }, [cognitoUser])

  useEffect(() => {
    if (subscription.status === 'active') {
      dispatch(setSubscribeModalOpen(false))
    }
  }, [subscription])

  async function checkCurrentUser() {
    try {
      let user = await Auth.currentAuthenticatedUser()

      const { attributes, signInUserSession } = user
      if (attributes['custom:paid']) {
        console.log('User is already paid')
      }
      dispatch(
        setCognitoUser({
          ...attributes,
          jwt: signInUserSession.accessToken.jwtToken,
        })
      )
      console.debug('Checking Here: attributes: ', user)
    } catch (error) {
      console.warn('Error Getting Current User: ', error)
    }
  }
  useEffect(() => {
    console.debug('Subscribe Modal Use Effect')
    checkCurrentUser()
  }, [])

  function PageOne() {
    return (
      <Grid container spacing={2}>
        <CircularProgress />
      </Grid>
    )
  }
  function PageTwo() {
    return (
      <Elements
        stripe={stripePromise}
        options={{
          clientSecret,
          appearance: {
            theme: 'night',
            labels: 'floating',
          },
        }}
      >
        <StripeForm clientSecret={clientSecret} />
      </Elements>
    )
  }
  return (
    <Dialog
      open={open}
      maxWidth="lg"
      PaperProps={{
        sx: {
          minWidth: '50%',
          overflow: 'hidden',
          //width: '100%',
          //maxWidth: '666px !important',
          //height: '100%',
          //maxHeight: '777px !important',
          alignItems: 'center',
          borderRadius: '16px',
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '90%',
          p: 2,
        }}
      >
        <Typography sx={{ fontSize: '24px', fontWeight: 700 }}>
          Subscribe
        </Typography>
        {/* <IconButton
          onClick={handleClose}
          sx={{ width: '42px', height: '42px' }}
        >
          <CloseIcon />
        </IconButton> */}
      </DialogTitle>
      {page === 0 && <DialogContent>{PageOne()}</DialogContent>}

      {page === 1 && clientSecret && (
        <DialogContent>
          <Grid container justifyContent="space-between" sx={{ pb: 2 }}>
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 400,
                color: 'text.secondary',
              }}
            >
              Full Access:
            </Typography>
            <Typography
              sx={{ fontSize: '18px', fontWeight: 700 }}
              color="primary"
            >
              $99.00
            </Typography>
          </Grid>

          {PageTwo()}
        </DialogContent>
      )}
    </Dialog>
  )
}
