import { Auth } from 'aws-amplify'
import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setAuthModalOpen } from '../redux/slices/uiSlice'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '../assets/icons/CloseIcon'
import { useState } from 'react'
import { Refresh, Visibility, VisibilityOff } from '@mui/icons-material'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import DisclaimerText from '../components/DisclaimerText'
import ForwardChevronIcon from '../assets/icons/ForwardChevronIcon'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

export default function AuthModal() {
  const theme = useTheme()
  const large = useMediaQuery(theme.breakpoints.up('sm'))
  const navigate = useNavigate()
  const [textFields, setTextFields] = useState({
    username: '',
    password: '',
    confirmationPassword: '',
    verificationCode: '',
    fullName: '',
  })
  const [loading, setLoading] = useState(false)
  const [agreed, setAgreed] = useState(false)

  const [confirming, setConfirming] = useState(false)
  const [disclaimer, setDisclaimer] = useState(false)
  const [disclaimerAgreed, setDisclaimerAgreed] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [passwordForgotten, setPasswordForgotten] = useState(false)
  const [isSignup, setIsSignup] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const userCognitoID = useSelector((state) => state.auth.cognitoUser?.sub)
  async function signUp() {
    setLoading(true)
    const { username, password, confirmationPassword, fullName } = textFields
    console.debug(username, password, confirmationPassword, fullName)
    try {
      const stripeAcct = await axios.post(
        'https://6ze3ptnn6lrdckucpdshskpcgy0wrtxd.lambda-url.us-east-2.on.aws/',
        {
          email: username,
          name: fullName,
        }
      )
      const data = stripeAcct.data
      const stripe_id = data.id
      // console.log('stripe_id: ', stripe_id)
      const { user } = await Auth.signUp({
        username,
        password,
        attributes: {
          'custom:stripe_customer_id': stripe_id,
        },
        autoSignIn: {
          // optional - enables auto sign in after user is confirmed
          enabled: true,
        },
      })
      // console.log(user)
      setErrorMessage('')
      setConfirming(true)
      setLoading(false)
    } catch (error) {
      console.error('error signing up:', error)
      setErrorMessage(error.message)
      setLoading(false)
    }
  }

  async function signIn() {
    const { username, password } = textFields
    console.debug(username, password)
    setLoading(true)
    try {
      const { user } = await Auth.signIn({
        username,
        password,
      })
      // console.log(user)
      setErrorMessage('')
      setDisclaimer(true)
      setLoading(false)
    } catch (error) {
      console.error('error signing up:', error)
      //console.log('error signing up message:', error.message)
      //if error
      setErrorMessage(error.message)
      setLoading(false)
    }
  }

  const open = useSelector((state) => state.ui.authModalOpen)

  const dispatch = useDispatch()

  const handleCloseAuthModal = () => {
    dispatch(setAuthModalOpen(false))
  }

  const handleChangeText = (prop) => (event) => {
    setTextFields({ ...textFields, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleAuthButtonClick = async () => {
    if (isSignup) {
      console.debug('Sign up here', textFields.username)
      setErrorMessage('')
      await signUp()
    } else {
      console.debug('Sign in here', textFields.username)
      setErrorMessage('')
      await signIn()
    }
  }

  const handleForgotPassword = async () => {
    if (textFields.username === '') {
      setErrorMessage('Please enter your email address to reset your password.')
      return
    }
    try {
      setConfirming(true)
      setPasswordForgotten(true)
      await Auth.forgotPassword(textFields.username)
      console.debug('code resent successfully')
    } catch (err) {
      console.error('error resending code: ', err)
    }
  }

  async function resendConfirmationCode() {
    try {
      await Auth.resendSignUp(textFields.username)
      console.debug('code resent successfully')
    } catch (err) {
      console.error('error resending code: ', err)
    }
  }

  const handleConfirmDisclaimer = () => {
    setDisclaimer(false)
    handleCloseAuthModal()
    navigate('/overview')
  }

  const handleCheckDisclaimer = () => {
    setDisclaimerAgreed(!disclaimerAgreed)
  }

  async function handleConfirmAccount() {
    try {
      await Auth.confirmSignUp(textFields.username, textFields.verificationCode)
      setConfirming(false)
      // handleCloseAuthModal()
      // navigate('/textbook')
      setDisclaimer(true)
      setErrorMessage('')
    } catch (error) {
      console.error('error confirming sign up', error)
      setErrorMessage(error.message)
    }
  }

  const handleResetPassword = async () => {
    try {
      await Auth.forgotPasswordSubmit(
        textFields.username,
        textFields.verificationCode,
        textFields.confirmationPassword
      )
      setPasswordForgotten(false)
      setDisclaimer(true)
      setErrorMessage('')
      setConfirming(false)
    } catch (err) {
      console.error('error submitting password reset', err)
      setErrorMessage(err.message)
    }
  }

  const signupDisabled =
    textFields.username === '' || textFields.password === '' || !agreed

  const signinDisabled =
    textFields.username === '' || textFields.password === ''

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      PaperProps={{
        sx: {
          minWidth: '50%',
          overflow: 'hidden',
          //width: '100%',
          //maxWidth: '666px !important',
          //height: '100%',
          //maxHeight: '777px !important',
          alignItems: 'center',
          borderRadius: '16px',
        },
      }}
    >
      <DialogTitle style={{ width: '95%' }}>
        <Grid container sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            {/*  {' '}
            <IconButton
              onClick={() => {
                //lastPage()
              }}
              style={{ width: '20px', height: '20px', marginLeft: '20px' }}
            >
              <ArrowBackIcon />
            </IconButton> */}
          </Grid>

          {!disclaimer && !confirming && (
            <IconButton onClick={handleCloseAuthModal}>
              <CloseIcon />
            </IconButton>
          )}
        </Grid>
      </DialogTitle>
      {disclaimer && (
        <DialogContent style={{}}>
          <DisclaimerText
            handleCheckDisclaimer={handleCheckDisclaimer}
            disclaimerAgreed={disclaimerAgreed}
          />
          <Grid container sx={{ p: 2 }}>
            <Button
              onClick={handleConfirmDisclaimer}
              variant="contained"
              color="primary"
              disabled={!disclaimerAgreed}
              sx={{ textTransform: 'none' }}
            >
              Continue
            </Button>
          </Grid>
        </DialogContent>
      )}
      {confirming && (
        <DialogContent style={{}}>
          <Grid container sx={{ p: 2 }}>
            <Typography sx={{ fontWeight: 700 }}>
              Check your email to verify your account
            </Typography>
          </Grid>
          {passwordForgotten && (
            <Grid container sx={{ p: 2 }}>
              <TextField
                sx={{ textAlign: 'center' }}
                value={textFields.confirmationPassword}
                onChange={handleChangeText('confirmationPassword')}
                variant="standard"
                placeholder="New Password"
                inputProps={{ min: 0, style: { textAlign: 'center' } }}
              />
            </Grid>
          )}

          <Grid container sx={{ justifyContent: 'space-around', pt: 2 }}>
            <Typography> Code: </Typography>
            <TextField
              sx={{ textAlign: 'center' }}
              value={textFields.verificationCode}
              onChange={handleChangeText('verificationCode')}
              variant="standard"
              placeholder="######"
              inputProps={{ min: 0, style: { textAlign: 'center' } }}
            />
            <Tooltip title="Submit">
              <IconButton
                onClick={
                  passwordForgotten ? handleResetPassword : handleConfirmAccount
                }
                sx={{
                  bgcolor: theme.palette.secondary.main,
                  width: '40px',
                  height: '40px',
                  ':hover': {
                    bgcolor: theme.palette.secondary.light,
                  },
                }}
              >
                <ForwardChevronIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          {errorMessage !== '' && (
            <Grid container sx={{ p: 2 }}>
              <Typography color="error">{errorMessage}</Typography>
            </Grid>
          )}
          <Grid container sx={{ p: 2 }}>
            <Button
              onClick={resendConfirmationCode}
              variant="text"
              color="secondary"
              sx={{ textTransform: 'none' }}
            >
              Resend Confirmation
              <Refresh />
            </Button>
          </Grid>
        </DialogContent>
      )}
      {!confirming && !disclaimer && (
        <DialogContent
          sx={{
            bgcolor: '#24CEB0',
            width: large ? '90%' : '100%',
            p: large ? 2 : 0,
            m: large ? 2 : 0,
            pb: 0,
            pr: 0,
            pt: 0,
            mt: 0,
            justifyContent: 'flex-end',
            backgroundImage: `url(/WelcomeSign.png)`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom left',
          }}
        >
          <Grid
            container
            sx={{ justifyContent: 'flex-end', alignItems: 'flex-start' }}
          >
            {large && (
              <Grid
                container
                sx={{
                  width: '30%',
                  alignItems: 'flex-start',

                  pt: 8,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '22px',
                    lineHeight: '27px',
                    color: 'white',
                  }}
                >
                  We're excited to see you!
                </Typography>
              </Grid>
            )}
            <Grid container sx={{ width: large ? '70%' : '100%' }}>
              <Card
                sx={{
                  width: '100%',
                  borderRadius: large ? '38px 0 0 38px' : 0,
                  p: large ? 6 : 2,
                  shadow: 0,
                }}
              >
                <Grid container sx={{ justifyContent: 'center' }}>
                  <Grid
                    container
                    sx={{ width: large ? '50%' : '100%', alignSelf: 'center' }}
                  >
                    <Grid container sx={{ p: 2, justifyContent: 'flex-start' }}>
                      <Typography
                        sx={{
                          fontWeight: '600',
                          fontSize: '25px',
                          lineHeight: '38px',
                        }}
                      >
                        {isSignup ? 'Create Account' : 'Login'}
                      </Typography>
                    </Grid>
                    {isSignup && (
                      <Grid
                        container
                        sx={{ p: 2, justifyContent: 'flex-start' }}
                      >
                        <TextField
                          label="Full Name"
                          value={textFields.fullName}
                          onChange={handleChangeText('fullName')}
                          variant="standard"
                          fullWidth
                        />
                      </Grid>
                    )}
                    <Grid container sx={{ p: 2, justifyContent: 'flex-start' }}>
                      <TextField
                        label="Email"
                        value={textFields.username}
                        onChange={handleChangeText('username')}
                        variant="standard"
                        fullWidth
                        placeholder="example@email.com"
                      />
                    </Grid>
                    <Grid container sx={{ p: 2, justifyContent: 'flex-start' }}>
                      <TextField
                        variant="standard"
                        type={showPassword ? 'text' : 'password'}
                        value={textFields.password}
                        onChange={handleChangeText('password')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility color="primary" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        placeholder="Password"
                      />

                      {!isSignup && (
                        <Button
                          sx={{ textTransform: 'none', p: 0, mt: 1 }}
                          onClick={handleForgotPassword}
                        >
                          <Typography
                            sx={{
                              fontSize: '14px',
                              color: 'primary.main',
                            }}
                          >
                            Forgot Password?
                          </Typography>
                        </Button>
                      )}
                    </Grid>
                    {errorMessage !== '' && (
                      <Grid container sx={{ p: 2 }}>
                        <Typography sx={{ color: 'red' }}>
                          {errorMessage}
                        </Typography>
                      </Grid>
                    )}
                    <Grid container sx={{ p: 1, pb: 2 }}>
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          width: '100%',
                          textTransform: 'none',
                          borderRadius: '9px',
                        }}
                        disabled={
                          loading || isSignup ? signupDisabled : signinDisabled
                        }
                        onClick={handleAuthButtonClick}
                      >
                        {loading && <CircularProgress size={20} />}
                        {isSignup ? 'Create Account' : 'Login'}
                      </Button>
                    </Grid>
                    <Grid container sx={{ p: 1 }}>
                      {isSignup && (
                        <Grid container sx={{ pb: 2 }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={agreed}
                                onChange={() => setAgreed(!agreed)}
                              />
                            }
                            label={
                              <Typography
                                sx={{
                                  color: 'text.secondary',
                                  ml: 1,
                                  textAlign: 'center',
                                  fontSize: '15px',
                                }}
                              >
                                I agree to the following{' '}
                                <a
                                  target="_blank"
                                  href="/privacy"
                                  style={{ color: '#24CEB0' }}
                                >
                                  Privacy Policy
                                </a>{' '}
                                and{' '}
                                <a
                                  target="_blank"
                                  href="/privacy"
                                  style={{ color: '#24CEB0' }}
                                >
                                  Terms & Conditions
                                </a>
                              </Typography>
                            }
                          />
                        </Grid>
                      )}
                      {!isSignup && (
                        <Grid container>
                          <Grid item sx={{ pr: 2 }}>
                            {' '}
                            <a target="_blank" href="/privacy">
                              Privacy Policy
                            </a>
                          </Grid>
                          <Grid item>
                            {' '}
                            <a target="_blank" href="/privacy">
                              Terms & Conditions
                            </a>
                          </Grid>
                        </Grid>
                      )}
                      <Typography
                        sx={{ color: 'text.secondary', fontSize: '15px' }}
                      >
                        {isSignup ? 'Already have an account?' : 'No Account?'}
                      </Typography>
                      <Button
                        onClick={() => setIsSignup(!isSignup)}
                        variant="text"
                        color="primary"
                        sx={{ textTransform: 'none' }}
                      >
                        {isSignup ? 'Login' : 'Sign up'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      )}
    </Dialog>
  )
}
