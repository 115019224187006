import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    memberSignedIn: false,
    cognitoUser: null,
    isTrainer: false,
    trainerId: 456,
    hasTrainerProfile: false,
  },
  reducers: {
    setIsTrainer: (state, action) => {
      state.isTrainer = action.payload
    },
    signOutCognitoUser: (state, action) => {
      state.memberSignedIn = false
      state.cognitoUser = null
    },
    setMemberSignedIn: (state, action) => {
      state.memberSignedIn = action.payload
    },
    setCognitoUser: (state, action) => {
      state.cognitoUser = action.payload
      if (action.payload['custom:user_type'] === 'trainer') {
        state.hasTrainerProfile = true
      } else {
        state.hasTrainerProfile = false
      }
      if (action.payload['custom:stripe_customer_id']) {
        state.stripeCustomerId = action.payload['custom:stripe_customer_id']
      }
      state.memberSignedIn = true
    },
    todoAdded(state, action) {
      state.push({
        id: action.payload.id,
        text: action.payload.text,
        completed: false,
      })
    },
    todoToggled(state, action) {
      const todo = state.find((todo) => todo.id === action.payload)
      todo.completed = !todo.completed
    },
  },
})

export const {
  setIsTrainer,
  signOutCognitoUser,
  setCognitoUser,
  setMemberSignedIn,
  todoAdded,
  todoToggled,
} = authSlice.actions
export default authSlice.reducer
