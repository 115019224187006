import { Grid, Typography } from '@mui/material'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import BottomNav from '../components/BottomNav'
import DetailsContainer from '../components/DetailsContainer'
import Header from '../components/Header'

export default function TriggerDetail() {
  const { id } = useParams()
  const [data, setData] = React.useState([
    {
      id: 1,
      slide: 'Trigger Point 1',
      image: 'TriggerPoints/TP1/1.jpg',
    },
    {
      id: 1,
      slide: 'Trigger Point 1',
      image: 'TriggerPoints/TP1/2.jpg',
    },
    {
      id: 1,
      slide: 'Trigger Point 1',
      image: 'TriggerPoints/TP1/3.jpg',
    },
    {
      id: 1,
      slide: 'Trigger Point 1',
      image: 'TriggerPoints/TP1/4.jpg',
    },
  ])
  const fetchPointDetails = async (id) => {
    try {
      //   const response = await fetch(
      //     `https://api.meridian-triggers.com/point-detail/${id}`,
      //     {
      //       method: 'GET',
      //       headers: {
      //         'Content-Type': 'application/json',
      //       },
      //     }
      //   )
      //   const data = await response.json()
      //   console.log('data: ', data)
    } catch (error) {
      console.error('error: ', error)
    }
  }

  React.useEffect(() => {
    // Fetch data from API
    fetchPointDetails(id)
  }, [id])

  return (
    <Grid
      container
      style={{
        height: '100vh',
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}
    >
      <Header />
      <DetailsContainer data={data} id={id} />
      <BottomNav />
    </Grid>
  )
}
