import { Grid, Typography } from '@mui/material'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import BottomNav from '../components/BottomNav'
import DetailsContainer from '../components/DetailsContainer'
import Header from '../components/Header'
import RegionCard from '../components/RegionCard'
import S3Image from '../components/S3Image'
import MuscleViewModal from '../modals/MuscleViewModal'

export default function TriggerMain() {
  const [selectedRegion, setSelectedRegion] = React.useState(null)
  const regions = [
    {
      id: 0,
      name: 'Head/Neck',
      image: 'MuscleRegions/headneckThumb.jpg',
    },
    {
      id: 1,
      name: 'Arm/Back',
      image: 'MuscleRegions/armbackThumb.jpg',
    },
    {
      id: 2,
      name: 'Wrist',
      image: 'MuscleRegions/wristThumb.jpg',
    },
    {
      id: 3,
      name: 'Chest',
      image: 'MuscleRegions/chestThumb.jpg',
    },
    {
      id: 4,
      name: 'Lumbar',
      image: 'MuscleRegions/lumbarThumb.jpg',
    },
    {
      id: 5,
      name: 'Thigh',
      image: 'MuscleRegions/thighThumb.jpg',
    },
    {
      id: 6,
      name: 'Calf/Foot',
      image: 'MuscleRegions/calffootThumb.jpg',
    },
  ]

  return (
    <Grid container>
      <Header />

      {/* <Grid
        container
        sx={{
          p: 4,
          pb: 2,
          justifyContent: 'flex-start',
        }}
      >
        <Typography sx={{ fontSize: 26, fontWeight: 700 }}>
          Region Selection
        </Typography>
      </Grid> */}

      <Grid container sx={{ p: 2, pt: 0 }}>
        {regions.map((region) => (
          <Grid
            item
            xs={region.id === selectedRegion ? 12 : 12}
            sm={region.id === selectedRegion ? 12 : 6}
            md={region.id === selectedRegion ? 12 : 4}
            key={region.name}
            sx={{ p: 2 }}
          >
            <RegionCard
              id={region.id}
              name={region.name}
              imagePath={region.image}
              expanded={selectedRegion === region.id}
              onClick={() =>
                setSelectedRegion(
                  selectedRegion === region.id ? null : region.id
                )
              }
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
