import * as React from 'react'
import Box from '@mui/material/Box'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import RestoreIcon from '@mui/icons-material/Restore'
import FavoriteIcon from '@mui/icons-material/Favorite'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setPointTabIndex } from '../redux/slices/uiSlice'
import {
  Accessibility,
  AccountTree,
  FitnessCenter,
  FlashOn,
  Polyline,
} from '@mui/icons-material'

export default function BottomNav() {
  const [value, setValue] = React.useState(0)
  const dispatch = useDispatch()
  const pointTabIndex = useSelector((state) => state.ui.pointTabIndex)
  const handleTabChange = (event, newValue) => {
    // console.log('event: ', event)
    // console.log('newValue: ', newValue)
    dispatch(setPointTabIndex(newValue))
  }
  return (
    <Box sx={{ width: '100%' }}>
      <BottomNavigation
        sx={{ width: '100%', justifyContent: 'space-between' }}
        showLabels
        value={pointTabIndex}
        onChange={handleTabChange}
      >
        <BottomNavigationAction
          label="Surface Anatomy"
          icon={<Accessibility />}
        />
        <BottomNavigationAction
          label="Relationship to Muscle"
          icon={<FitnessCenter />}
        />
        <BottomNavigationAction
          label="Referred-Pain Pattern"
          icon={<FlashOn />}
        />
        <BottomNavigationAction
          label="Referred-Pain To Channel"
          icon={<AccountTree />}
        />
      </BottomNavigation>
    </Box>
  )
}
