import { PDFViewer } from '@react-pdf/renderer'
import { Grid, Typography } from '@mui/material'
import Header from '../components/Header'
//import UserAgreement from '../UserAgreement.pdf';

function Overview() {
  const references = [
    `Travell JG, Simons DG. Myofascial Pain & Dysfunction: the Trigger Point
Manual. Vol. 1. Baltimore: Williams & Wilkins, 1983, 713 p. `,
    ` Travell JG, Simons DG. Myofascial pain and Dysfunction: the Trigger Point
Manual. Vol. 2, The Lower Extremities. Baltimore: Williams & Wilkins, 1992, 607
p.`,
    `O’Connor J, Bensky D. Acupuncture: A Comprehensive Text. Chicago: Eastland
Press; 1981, 741 p.`,
    `Deadman P, Al-Khafaji M, Baker K. A Manual of Acupuncture. Hove: Journal of
Chinese Medicine Publications; 1998, 671 p.`,
    `Dorsher PT. Myofascial referred-pain data provide physiologic evidence of
acupuncture meridians. J Pain 2009; 10(7):723–73.`,
    `Dorsher PT, Fleckenstein J. Trigger points and classical acupuncture points: part
1: qualitative and quantitative anatomic correspondences. Dt Ztschr f Akup 2008;
51(2):15-24.`,
    `Dorsher PT, Fleckenstein J. Trigger points and classical acupuncture points: part
2: clinical correspondences in treating pain and somatovisceral disorders. Dt
Ztschr f Akup 2008;51(3):6-11. `,
    `Dorsher PT, Fleckenstein J. Trigger points and classical acupuncture points. Part
3: Relationships of myofascial referred pain patterns to acupuncture meridians.
Dt Ztschr f Akup 2009; 52(1):6-11. DOI: 10.1016/J.DZA.2009.01.001`,
    `Dorsher PT, Cummings M. Anatomy for acupuncture [DVD-ROM]. London:
Primal Pictures Limited, 2007. (now available only online at Primal Pictures
website)`,
    `Chen E. Cross-sectional anatomy of acupoints. Edinburgh: Churchill Livingstone,
1995.`,
    `Donnelly, Joseph M. Travell, Simons & Simons’ Myofascial Pain and
Dysfunction : the Trigger Point Manual / Joseph M. Donnelly [and Three Others].
Third edition. Philadelphia, Pennsylvania: Wolters Kluwer, 2019. Print.`,
    `Rinzler SH, Travell J: Therapy directed at the somatic component of cardiac pain.
Am Heart J 1948; 35:248-268.`,
    `Sola AE, Kuitert JH. Myofascial trigger point pain in the neck and shoulder girdle.
Northwest Med (Seattle) 1955; 54: 980-984.`,
    `Kennard MA, Haugen FP. The relationship of subcutaneous focal sensitivity to
referred pain of cardiac origin. Anesthesiology 1955; 16: 297-311.`,
    `Birch S: Trigger point—Acupuncture point correlations revisited. J Altern
Complement Med 2003; 9:91–103.`,
    `Zhu M (translator). The Medical Classic of the Yellow Emperor. Beijing: Foreign
Languages Press; 2001, 301 p.`,
  ]
  return (
    <>
      <Header />
      <Grid
        container
        //justify="center"
        justifyContent="start"
        sx={{ p: 2 }}
        style={
          {
            // height: '100vh',
          }
        }
      >
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 24 }}>
            Overview
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 24 }}>
            Anatomic, Clinical and Physiological Relationships of Trigger Points
            & Classical Acupuncture Points with Data Summaries
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 20 }}>
            Introduction and Background
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 18 }}>
            This APP provides a visual and written comparisons of the 2200+
            year-old acupuncture tradition to the contemporary myofascial pain
            syndrome tradition. These comparisons include their anatomic,
            clinical and physiologic information for the most common, clinically
            important trigger points and acupuncture points, as defined by each
            tradition. This APP also provides guidance on locating these trigger
            and acupuncture points as well as guidance regarding direction and
            depth of needling at these points derived from the acupuncture
            tradition, which also has relevance to dry needling of trigger
            points. Note: as per the APP disclaimer the user has already
            accepted, only licensed, credentialed/trained individuals are
            legally allowed to insert needles into people or animals—use of any
            information in this APP does not negate state (and country)
            statutory legal education/training requirements that the user
            complete formal training in dry needling or acupuncture (including
            hands-on needling skills training).
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 18 }}>
            Practitioners versed in the myofascial pain syndrome and acupuncture
            traditions likely already have sensed the two traditions’ have
            similarities, and this APP provides written and visual/graphic
            evidence of the fundamental anatomic, clinical (pain and non-pain
            uses) and physiologic (myofascial referred-pain to acupuncture
            channel) correspondences of the “most common” trigger points (as
            defined and illustrated in the 1st edition of the Travell and
            Simons’ <em>Trigger Point Manual </em>) <sup>1,2 </sup> &nbsp; to
            Classical acupuncture points (acupoints), which were mostly (96%)
            described by 400 CE. <sup>3,4</sup> The “most common” myofascial
            trigger points’ anatomic locations, clinical indications, and
            referredpain distributions overwhelmingly overlap with those of
            Classical acupuncture points and the “14 Channels” that they exist
            on (see “APP Terminology” section for descriptions of these terms).
            Myofascial referred-pain data actually serves to validate the
            concept and distributions of the Primary acupuncture channels{' '}
            <sup>5</sup> , providing further evidence that trigger point
            referred-pain is physiologically analogous to the spread of “needle
            sensation” away from the site of needling during acupuncture
            treatment. Myofascial pain syndrome data then represents a partial
            re-discovery of the acupuncture tradition’s findings described 2000+
            years previously, though with very different “languages”. The
            myofascial pain syndrome data serves to validate Classical
            acupuncture point locations, their clinical indications (pain and
            non-pain), and the “14 Channels” they exist on.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 18 }}>
            There is data from 255 “most common” myofascial trigger point
            regions illustrated in the 1st edition of the{' '}
            <em>Trigger Point Manual</em>&nbsp; to compare to 361 Classical
            acupuncture points within this APP. The locations of 241 of the
            “most common” trigger points anatomically correspond to Classical
            acupuncture points- that is, they are anatomically proximate (near)
            to each other and influence the same muscle region. This trigger
            point- acupoint database was validated in 2007 at University of
            Munich by Dr. Dominik Irnich’s research group (Dr. Johannes
            Fleckenstein) with the results published in the{' '}
            <em>Deutsche Zeitschrift für Akupunktur</em>&nbsp; journal (the
            official journal of the German Medical Association for Acupuncture)
            as a three article series (available for free online as open access
            articles). <sup>6,7,8</sup> &nbsp;This trigger point-acupuncture
            point database was updated in 2020 using multiple newer digital
            anatomy resources, including Primal Pictures’{' '}
            <em>Anatomy for Acupuncture </em>
            <sup>9</sup>, which allow optimal visualization of the anatomy of
            these trigger point and acupoint locations. Eachou Chen’s{' '}
            <em>Cross Sectional Anatomy of Acupoints</em> <sup>10</sup> was also
            used for anatomic confirmation of Classical acupoint relationships
            to bodily muscles and muscle regions. The “most common” trigger
            point to Classical acupuncture point anatomic correspondences found
            with the 2020 database are unchanged from the 2007 database in 97%
            of comparisons, and the updated 2020 analysis actually led to
            discovery of more trigger point - acupoint correspondences. The
            digital anatomy resources thus confirmed the fundamental accuracy of
            the 2007 “most common” trigger point to Classical acupoint database,
            even accommodating the contemporary view of some myofascial pain
            researchers that trigger points are muscle regions (abandoning the
            “X” locations of the “most common” trigger points illustrated in the
            first edition of the Trigger Point Manual). Even if this
            contemporary view of trigger point regions is considered, by my
            estimation there are still some 200 distinct trigger point regions
            (anatomically distant from adjacent trigger points in the same
            muscle and/or trigger point regions with differing referred-pain
            patterns described). In that scenario, the number of “most common”
            trigger point locations described and illustrated in the 1st edition
            of Travell and Simons is roughly half the number of Classical
            acupuncture points. Note that in the 3 decades since the 1st edition
            of the Trigger Point Manual completed publication in 1992, there
            have been only a handful of new trigger point regions with
            referred-pain patterns described.<sup>11</sup>
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 18 }}>
            The myofascial trigger point locations described and illustrated in
            the 1st edition of the <em>Trigger Point Manual </em> are not the
            only trigger point locations in the body per Travell and Simons, but
            rather are “the most common trigger point locations” (page 20,
            volume 1, first edition).<sup>1</sup> Historically, early myofascial
            pain researchers such as Rinzler and Travell <sup>12</sup>, Sola et
            al. <sup>13</sup> , and Kennard and Haugen <sup>14</sup> all
            localized shoulder girdle trigger point regions with “X”’s in
            anatomically similar locations in shoulder girdle musculature.
            Further, in the first edition of the
            <em>Trigger Point Manual </em>
            <sup>1</sup> , trapezius trigger points 2, 5, and 7 are anatomically
            close to each other in the middle portion of the trapezius muscle,
            yet have three distinct referred-pain patterns (see Figure 1).
            Trigger point 7 in the trapezius is illustrated as an oval region
            with trigger points 2 and 5 locations located at the edges of the
            trigger point 7 region superiorly and inferiorly, respectively.
            Further, the muscle fibers of these three trigger point regions
            course in the same general (mostly horizontal) direction in the
            mid-trapezius muscle.
          </Typography>
        </Grid>
        <Grid container sx={{ p: 1 }}>
          <img src="/overview1.png" style={{ width: '80%', height: 'auto' }} />
        </Grid>
        <Grid item xs={12} sx={{ p: 1, pl: 4, pr: 8 }}>
          <Typography sx={{ fontSize: 12 }}>
            Figure 1. Trapezius trigger points 2, 5, and 7 regions and their
            referred-pain patterns shown, with TP-7 region delineated by orange
            oval that encompasses TP-2 and TP-5 at its margins. TP-5
            referred-pain (pink) follows the Bladder channel (blue) while TP-2
            referred-pain (light green) and TP-7 referred-pain (orange)
            distributions follow the Triple Energizer channel (orange).
            Anatomically corresponding Classical acupuncture points as listed.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 18 }}>
            Modern myofascial theorists who have recommended abandoning the “X”
            anatomic anchors illustrating the “most common” trigger point region
            locations in the 1st edition of the <em>Trigger Point Manual</em>{' '}
            then cannot accurately define the anatomic borders of these three
            adjacent trigger point regions, which have 3 distinct referred-pain
            patterns. As shown in the Figure 1, these 3 trapezius ”most common”
            trigger point regions correspond anatomically to two different
            Classical acupuncture points, whose associated Channels accurately
            predict those 3 trigger points’ referred-pain distributions. TP-2 is
            anatomically near acupuncture point TE-15 and has referred-pain (in
            light green) superiorly towards the back of the head in the Triple
            Energizer channel distribution, while TP-7 is also near TE-15 but
            its referred-pain (in orange) is to the posterior upper arm and
            forearm along the Triple Energizer channel distribution. TP-5 is
            anatomically adjacent to the BL-42 acupuncture point and its
            referred pain (in pink) is along the Bladder channel distribution.
            These corresponding Classical acupuncture points also have similar
            clinical pain indications <sup>3,4,10</sup> as those described in
            the <em>Trigger Point Manual</em> for those three trigger point
            regions.<sup>1</sup>
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 18 }}>
            Classical acupuncture point locations, clinical indications,
            Channels, and needling instructions are well delineated in
            acupuncture references such as those by Deadman, O’Connor and Bensky
            and Chen <sup>3,4,10</sup> but also available online at websites
            including Acupuncture.com and YinYang House.com. The Classical
            acupoints are not the only acupuncture points, as those reference
            texts describe literally hundreds of other acupoints, the so-called
            “miscellaneous” and “new” acupuncture points, which are almost all
            non-channel in location (so do not allow for comparison of
            referred-pain to channel distributions). <sup>3,4</sup> The
            Classical acupuncture points can be viewed as the most common and/or
            clinically important acupoints for clinical practice, based on
            thousands of years of accumulated acupuncture clinical knowledge and
            experience. The Classical acupuncture points also, by definition,
            exist on the “14 Channels” (meridians) that join acupuncture points
            with related clinical properties and are named for the Organs these
            channels influence (Figure 2). There is also a category of
            acupuncture points termed “ashi” (“that’s it”) acupoints, which are
            focal tender points at the site of the patient’s symptomatic pain
            complaint. Some acupuncture researchers <sup>15</sup> have asserted
            trigger points can only correspond to ashi or Muscle channel points,
            but acupuncture theory <sup>3</sup> contradicts this view.
          </Typography>
        </Grid>
        <Grid container sx={{ p: 1, pt: 4 }}>
          <img src="/overview2.png" style={{ width: '80%', height: 'auto' }} />
        </Grid>
        <Grid item xs={12} sx={{ p: 1, pl: 4 }}>
          <Typography sx={{ fontSize: 12 }}>
            Figure 2. The acupuncture “14 channels” on which the Classical
            acupuncture points exist (12 “Primary” channels and the Conception
            Vessel and Governing Vessel “extraordinary” channels)
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 18 }}>
            There are twelve Primary acupuncture channels. These are
            alphabetically by name (followed by standard abbreviation): Bladder
            (BL), Gallbladder (GB), Heart (HT), Kidney (KI), Large Intestine
            (LI), Liver (LR), Lung (LU), Pericardium (PC), Small Intestine (SI),
            Spleen (SP), Stomach (ST), and Triple Energizer (TE). Two of the
            eight “extraordinary” channels, namely the Conception Vessel (CV)
            channel on the midline of the anterior body and the Governing Vessel
            (GV) channel on the midline of the posterior body, are considered
            with the twelve Primary channels as they have their own named
            Classical acupuncture points and their clinical importance.
            <sup>3</sup> Thus, these “14 Channels” are usually represented on
            acupuncture channel schematics. These channels join functionally
            interconnected acupuncture points, and are believed to have been
            developed by observations of spread of needle sensation during
            acupuncture needling in clinical practice that joined acupuncture
            points with similar therapeutic properties.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 24, fontWeight: 'bold' }}>
            Overall Results of Trigger Point to Classical Acupoint Comparisons
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 22, fontWeight: 'bold' }}>
            <em>Anatomic Correspondences</em>
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 18 }}>
            The results of this APP’s systematic comparison of the “most common”
            trigger points and Classical acupuncture points speak for
            themselves- there is 95% (241/255) overlap of their anatomic
            locations (proximate to each other and influencing the same muscle
            regions). This is illustrated in Figure 2.
          </Typography>
        </Grid>
        <Grid container sx={{ p: 1, pt: 4 }}>
          <img src="/overview3.png" style={{ width: '80%', height: 'auto' }} />
        </Grid>
        <Grid item xs={12} sx={{ p: 1, pl: 4 }}>
          <Typography sx={{ fontSize: 12 }}>
            Figure 3. “Most common” trigger point to Classical acupuncture point
            correspondences.{' '}
            <em>
              Blue = anatomically corresponding point pairs, Red = anatomically
              non-corresponding point pairs, Green = internal (anatomically
              non-accessible) trigger points
            </em>
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 22, fontWeight: 'bold' }}>
            <em>
              Clinical Correspondences of Anatomically Corresponding
              TrP-Acupoint Pairs
            </em>
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 18 }}>
            For each of the 241 “most common” trigger point to Classical
            acupoint correspondences (anatomically) found, the pain and non-pain
            (somatovisceral) clinical indications of those point pairs were
            examined for similarities. Note Travell and Simons’ 1st edition
            described non-pain indications for at least 20% of the “most common”
            myofascial trigger points described and illustrated therein.
            <sup>1,2</sup> &nbsp; Examples include trigger points that are
            associated with tinnitus (“ringing in the ears”), dry cough,
            belching, and diarrhea.
            <sup>1,2</sup> 17 of the “most common” trigger points have only
            non-pain indications described including the “cardiac arrhythmia”,
            “belch button” and “causes diarrhea” trigger points.<sup>1</sup>{' '}
            Anatomically corresponding point pairs have the same pain
            indications described in 217/224 (97%) of comparisons. The
            statistical odds that this 97% pain indication correspondence would
            occur by chance is essentially zero (~ 1/2<sup>217</sup> = ~ 1 in 2
            x 10<sup>65</sup>). Anatomically corresponding TrP-acupoint pairs’
            somatovisceral, non-pain indications correspond in 44/50 (88%) of
            comparisons. The statistical odds that this degree of correspondence
            of non-pain indications would occur by chance is likewise
            infinitesimally small (~1/2<sup>44</sup> = ~1 in 2 X 10<sup>13</sup>{' '}
            = ~1 in 10 trillion).{' '}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 22, fontWeight: 'bold' }}>
            <em>
              Physiologic Correspondences of Anatomically Corresponding
              TrP-Acupoint Pairs
            </em>
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 18 }}>
            The relationship of a trigger point’s referred-pain distribution to
            that of its anatomically corresponding Classical acupuncture point’s
            Primary channel is also presented graphically in the APP. The
            criteria used for grading the degree of overlap of referredpain and
            acupuncture channel distributions for anatomically corresponding
            trigger point - Classical acupuncture point pairs is outlined in the
            “APP Definitions” section. The degree of overlap was grade
            “excellent” in 138/224 (62%) of comparisons, “good” in 34/224 (15%)
            of comparisons, and “fair” in 31/224 (14%) of comparisons. Thus in
            only 9% of comparisons was there little or no overlap of
            referred-pain and acupuncture channel distributions for anatomically
            corresponding trigger point – acupoint pairs.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 18 }}>
            Further, the 91% overlap of referred-pains and channel distributions
            allows myofascial referred-pain to validate the trajectories of the
            Primary channels (see Figure 4).
          </Typography>
        </Grid>
        <Grid container sx={{ p: 1, pt: 4 }}>
          <img src="/overview4.png" style={{ width: '80%', height: 'auto' }} />
        </Grid>
        <Grid item xs={12} sx={{ p: 1, pl: 4 }}>
          <Typography sx={{ fontSize: 12 }}>
            Figure 4. Myofascial referred-pain data can be used to re-construct
            acupuncture Primary Channels (Lung channel shown in white and
            Bladder channel shown in blue). Trigger points shown as white “X”’s
            and their referred-pains in red with solid red representing intense
            referred-pain while red dots represent lesser referred-pain)
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 18 }}>
            As shown in Figure 4, trigger points that anatomically correspond to
            Bladder (BL) Classical acupuncture points have referred-pain
            distributions that follow the Bladder channel distribution, and when
            summed together serve to replicate the Bladder channel from the
            frontal region of the head all the way down to the fifth toe.
            Similarly, trigger points that anatomically correspond to Lung (LU)
            Classical acupuncture points have referred-pain distributions mostly
            along the anterior chest and volar- radial upper extremity in a Lung
            channel distribution, and summed together serve to replicate the
            course of the Lung channel from the anterior chest down the arm to
            the thumb. Further examples can be seen in the 2009 Journal of Pain
            publication.<sup>5</sup>
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 24, fontWeight: 'bold' }}>
            Conclusions
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 18 }}>
            &nbsp;&nbsp;&nbsp;&nbsp; This APP’s data documents that there is 95%
            anatomic correspondence, 97% pain indication correspondence, 88%
            non-pain (somatovisceral) indication correspondence, and 91%
            physiologic correspondence (myofascial referred-pain to acupuncture
            channel) present between the “most common” trigger point regions (as
            described and illustrated in the 1st edition of the{' '}
            <em>Trigger Point Manual</em> <sup>1,2</sup>) and the Classical
            acupuncture points. These results demonstrate that myofascial pain
            syndrome data represents a partial re-discovery of 2000+ year-old
            acupuncture tradition data, though described using very different
            “languages”. The myofascial pain syndrome data is only a partial
            discovery of the acupuncture tradition data, as even in the regional
            view of trigger points currently in vogue, the{' '}
            <em>Trigger Point Manual</em>
            describes only about 200 distinct trigger point regions
            (anatomically and/or physiologically differing), in contrast to 361
            Classical acupuncture points plus hundreds more “miscellaneous” and
            “new” acupuncture points described.<sup>3,4</sup> Again, with 3
            decades passing since the <em>Trigger Point Manual</em> was first
            published, there have only been 5 new trigger point regions
            described in the third edition of this reference text.<sup>11</sup>{' '}
            Further details of the trigger point – acupuncture point
            correspondence data will be presented in a future peer-reviewed
            journal publication.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 18 }}>
            &nbsp;&nbsp;&nbsp;&nbsp; The intent of this APP is not to show that
            trigger point dry needling is better or worse than acupuncture, but
            rather that as myofascial pain data serves to validate acupuncture
            point locations, clinical actions, and acupuncture channels,
            myofascial pain practitioners can benefit by the 2000+ years of
            accumulated clinical experience and knowledge contained in the
            acupuncture tradition database. Conversely, acupuncturists can
            benefit by the clinical research, scientific techniques, and data
            outlined by the myofascial pain syndrome database. The evident
            brilliance and clinical acumen of the founders of the Traditional
            Chinese Medicine acupuncture tradition, however, cannot be
            understated or underestimated. The 2200- year old{' '}
            <em>Huangdi Nei Jing</em>
            treatise that first compiled the descriptions of acupuncture theory
            in fact offers the first descriptions of myofascial pain syndrome
            and trigger points- “The pains of multitude impediment occur at
            fixed places…The wind, cold, and dampness settle in the flesh…and
            condense. The condensation extrudes the textures of the muscles to
            make them split… causing pain and… the pain makes the blood converge
            which produces heat (<em>inflammation</em>).” <sup>16</sup>
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 24, fontWeight: 'bold' }}>
            References
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          {references.map((reference, index) => (
            <Typography key={index} sx={{ fontSize: 18, pb: 1, pl: 2 }}>
              {index + 1}) {reference}
            </Typography>
          ))}
        </Grid>

        {/* <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 18 }}>
            
          </Typography>
        </Grid> */}
        {/* <object
          data="/overview.pdf"
          type="application/pdf"
          width="100%"
          height="100%"
        /> */}

        {/* <iframe src="/overview.pdf" width="100%" height="100%"></iframe> */}
      </Grid>
    </>
  )
}

export default Overview
