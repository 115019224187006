import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import {
  CardActionArea,
  Collapse,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import S3Image from './S3Image'
import { muscleImages } from '../constants/muscleImages'
import muscles from '../constants/muscles.json'
import { useDispatch } from 'react-redux'
import {
  setMuscleViewModalOpen,
  setSelectedMuscleModalImage,
} from '../redux/slices/uiSlice'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

export default function RegionCard({ expanded, id, name, imagePath, onClick }) {
  const theme = useTheme()
  const large = useMediaQuery(theme.breakpoints.up('sm'))
  const dispatch = useDispatch()
  const handleOpenMuscleViewModal = (image, name, triggerPoints) => {
    dispatch(setMuscleViewModalOpen(true))
    dispatch(setSelectedMuscleModalImage({ image, name, triggerPoints }))
  }
  return (
    <Card variant="outlined" sx={{ borderRadius: '36px' }}>
      <CardActionArea onClick={onClick}>
        <Grid container sx={{ bgcolor: 'black' }}>
          <S3Image imagePath={imagePath} />
        </Grid>

        <CardContent>
          <Grid container sx={{ justifyContent: 'space-between' }}>
            <Grid item xs={9}>
              <Typography
                sx={{
                  fontSize: large ? '24px' : '18px',
                  fontWeight: 700,
                }}
                component="div"
              >
                {name} Region
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Grid container>
                <IconButton sx={{ border: 1, borderColor: 'primary.main' }}>
                  {expanded ? (
                    <ExpandLess
                      sx={{ fontSize: '24px', color: 'primary.main' }}
                    />
                  ) : (
                    <ExpandMore sx={{ fontSize: '24px' }} />
                  )}
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
      {muscleImages[id] && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent sx={{ bgcolor: 'rgba(76, 206, 245, 0.2)' }}>
            <Grid container>
              {muscleImages[id].map((muscle, index) => (
                <Grid item xs={12} sm={6} md={4} key={index} sx={{ p: 2 }}>
                  <Card variant="outlined" sx={{ borderRadius: '36px' }}>
                    <CardActionArea
                      onClick={() =>
                        handleOpenMuscleViewModal(
                          muscle,
                          muscles.muscleRegions[id].muscles[index].name,
                          muscles.muscleRegions[id].muscles[index].triggerPoints
                        )
                      }
                    >
                      <Grid container sx={{ bgcolor: 'black' }}>
                        <S3Image imagePath={muscle} nullRadius />
                      </Grid>

                      <CardContent>
                        <Grid container>
                          <Typography sx={{ fontWeight: 600 }}>
                            {muscles.muscleRegions[id].muscles[index].name}
                          </Typography>
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Collapse>
      )}
    </Card>
  )
}
