import React from 'react'
import { createBrowserRouter, Navigate } from 'react-router-dom'
import './index.css'
import './App.css'
import ErrorPage from './pages/Error'
import Landing from './pages/Landing'
import Privacy from './pages/Privacy'
import TriggerDetail from './pages/TriggerDetail'
import TextBook from './pages/TextBook'
import TriggerMain from './pages/TriggerMain'
import { useSelector } from 'react-redux'
import Overview from './pages/Overview'
import Home from './pages/Home'
import Account from './pages/Account'

const ProtectedRoute = ({ redirectPath = '/landing', children }) => {
  const user = useSelector((state) => state.auth.memberSignedIn)

  if (!user) {
    return <Navigate to={redirectPath} replace />
  }

  return children
}

const PaidRoute = ({ redirectPath = '/', children }) => {
  //const subscription = useSelector((state) => state.ui.subscription)
  const user = useSelector((state) => state.auth.cognitoUser)
  const isPaid = user['custom:paid'] === 'true'
  if (!isPaid) {
    return <Navigate to={redirectPath} replace />
  }

  return children
}

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Home />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/privacy',
    element: <Privacy />,
  },
  {
    path: '/point-detail/:id',
    element: (
      <ProtectedRoute>
        <TriggerDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: '/account',
    element: (
      <ProtectedRoute>
        <Account />
      </ProtectedRoute>
    ),
  },
  {
    path: '/textbook',

    element: (
      <ProtectedRoute>
        <TextBook />
      </ProtectedRoute>
    ),
  },
  {
    path: '/landing',
    element: <Landing />,
  },
  {
    path: '/overview',
    element: <Overview />,
  },
  {
    path: '/home',
    element: (
      <ProtectedRoute>
        <Landing />
      </ProtectedRoute>
    ),
  },
  {
    path: '/triggers',
    element: (
      <PaidRoute>
        <TriggerMain />
      </PaidRoute>
    ),
    errorElement: <ErrorPage />,
  },
])

export default router
