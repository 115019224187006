import '../App.css'
import React, { useEffect, useState } from 'react'
import logo from '../logo.svg'
import Header from '../components/Header'
import { Storage } from 'aws-amplify'
import S3Image from '../components/S3Image'
import {
  Button,
  Fab,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Typography,
  useTheme,
} from '@mui/material'
import { Box } from '@mui/system'
import { ChevronRight, Circle, CircleOutlined } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { setAuthModalOpen } from '../redux/slices/uiSlice'
import { useNavigate } from 'react-router-dom'
import BackChevronIcon from '../assets/icons/BackChevronIcon'
import ForwardChevronIcon from '../assets/icons/ForwardChevronIcon'
import useMediaQuery from '@mui/material/useMediaQuery'

function Landing() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [image, setImage] = useState(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const theme = useTheme()
  const large = useMediaQuery(theme.breakpoints.up('md'))

  const handleOpenAuthModal = () => {
    dispatch(setAuthModalOpen(true))
  }

  const userCognitoID = useSelector((state) => state.auth.cognitoUser?.sub)

  // useEffect(() => {
  //   if (userCognitoID) {
  //     navigate('/home')
  //   }
  // }, [userCognitoID])

  const itemData = [
    {
      img: '/Landing2.jpg',
      title: 'Doors',
    },
    {
      img: '/Landing4.jpg',
      title: 'Kitchen',
    },
    {
      img: '/Landing1.png',
      title: 'Books',
    },

    {
      img: '/Landing3.png',
      title: 'Candle',
    },
    {
      img: '/Landing5.jpg',
      title: 'leg',
    },
  ]

  const HeroSlides = [
    {
      image: '/Hero1.png',
      title: 'Books',
    },
    {
      image: '/Hero2.png',
      title: 'Doors',
    },
    {
      image: '/Hero3.png',
      title: 'Candle',
    },
  ]

  function MasonryImageList() {
    return (
      <Box sx={{ overflowY: 'scroll', m: 2, height: '100%' }}>
        <ImageList variant="masonry" cols={2} gap={8}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                style={{ borderRadius: '36px' }}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    )
  }

  return (
    <div className="App">
      <Header />
      <Grid
        container
        sx={{
          flexGrow: 1,
          justifyContent: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <Grid
          container
          sx={{
            width: '100%',
            height: '40vh',
            bgcolor:
              currentSlide === 0
                ? '#18B7E6'
                : currentSlide === 1
                ? '#4DCFF5'
                : '#6AC4DE',
            backgroundImage: `url(${HeroSlides[currentSlide].image})`,
            backgroundSize:
              currentSlide === 1 || currentSlide === 2 ? 'cover' : 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition:
              currentSlide === 0
                ? 'bottom right'
                : currentSlide === 1
                ? 'bottom left'
                : 'bottom left',
            pt: 4,
          }}
        >
          <Grid
            container
            sx={{
              height: '90%',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              pb: 4,
            }}
          >
            {large && (
              <Grid
                item
                sx={{
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Grid
                  container
                  sx={{
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    pl: 4,
                  }}
                >
                  <Grid
                    container
                    sx={{
                      width: large ? '78px' : '48px',
                      height: large ? '78px' : '48px',
                      background: 'rgba(255, 255, 255, 0.35)',
                      borderRadius: '16px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (currentSlide > 0) {
                        setCurrentSlide(currentSlide - 1)
                      } else {
                        setCurrentSlide(HeroSlides.length - 1)
                      }
                    }}
                  >
                    <ForwardChevronIcon backwards />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid
              item
              sx={{
                flexGrow: 1,

                height: '100%',
                alignItems: 'flex-end',
              }}
            >
              {currentSlide === 0 && (
                <Grid
                  container
                  sx={{
                    p: 2,
                    pb: 0,
                    justifyContent: 'flex-start',
                    alignItems: 'flex-end',
                  }}
                >
                  <Typography
                    sx={{
                      color: 'black',
                      fontFamily: 'Jockey One',
                      fontSize: large ? '48px' : '32px',
                    }}
                  >
                    YOUR SOURCE FOR TRIGGER POINT
                  </Typography>
                </Grid>
              )}
              {currentSlide === 0 && (
                <Grid
                  container
                  sx={{
                    p: 2,
                    pt: 0,
                    justifyContent: 'flex-start',
                    alignItems: 'flex-end',
                  }}
                >
                  <Typography
                    sx={{
                      color: 'white',
                      fontFamily: 'Jockey One',
                      fontSize: large ? '48px' : '32px',
                    }}
                  >
                    & ACUPUNCTURE EDUCATION
                  </Typography>
                </Grid>
              )}
              {currentSlide === 1 && (
                <Grid
                  container
                  sx={{
                    p: 2,
                    pt: 10,
                    pb: 10,
                    justifyContent: 'space-around',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: 'BLACK',
                      fontFamily: 'Jockey One',
                      fontSize: large ? '48px' : '32px',
                    }}
                  >
                    LEARN
                  </Typography>
                  <Typography
                    sx={{
                      color: 'white',
                      fontFamily: 'Jockey One',
                      fontSize: large ? '48px' : '32px',
                    }}
                  >
                    VIRTUALLY
                  </Typography>
                </Grid>
              )}
              {currentSlide === 2 && (
                <Grid
                  container
                  sx={{
                    p: 2,
                    pb: 0,
                    justifyContent: 'flex-start',
                    alignItems: 'flex-end',
                  }}
                >
                  <Typography
                    sx={{
                      color: 'white',
                      fontFamily: 'Jockey One',
                      fontSize: large ? '48px' : '32px',
                    }}
                  >
                    MILLENIA OF LEARNING
                  </Typography>
                </Grid>
              )}
              {currentSlide === 2 && (
                <Grid
                  container
                  sx={{
                    p: 2,
                    pt: 0,
                    justifyContent: 'flex-start',
                    alignItems: 'flex-end',
                  }}
                >
                  <Typography
                    sx={{
                      color: 'black',
                      fontFamily: 'Jockey One',
                      fontSize: large ? '48px' : '32px',
                    }}
                  >
                    AT YOUR FINGERTIPS
                  </Typography>
                </Grid>
              )}
              <Grid
                container
                sx={{ justifyContent: 'flex-start', p: 2, mt: 4 }}
              >
                <Fab
                  onClick={handleOpenAuthModal}
                  variant="extended"
                  color="primary"
                  sx={{
                    // position: 'relative',
                    // top: '70%',
                    // right: '-30%',
                    color: 'white',
                    pl: 4,
                    pr: 4,
                    background: '#24CEB0',
                    borderRadius: '50px',
                    fontFamily: 'Jockey One',
                    fontSize: large ? '28px' : '16px',

                    lineHeight: '39px',
                    zIndex: 2,
                  }}
                >
                  Sign Up Now
                </Fab>
              </Grid>
            </Grid>
            {large && (
              <Grid
                item
                sx={{
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Grid
                  container
                  sx={{
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    pr: 4,
                  }}
                >
                  <Grid
                    container
                    sx={{
                      width: large ? '78px' : '48px',
                      height: large ? '78px' : '48px',
                      background: 'rgba(255, 255, 255, 0.35)',
                      borderRadius: '16px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (currentSlide < HeroSlides.length - 1) {
                        setCurrentSlide(currentSlide + 1)
                      } else {
                        setCurrentSlide(0)
                      }
                    }}
                  >
                    <ForwardChevronIcon />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              sx={{
                justifyContent: 'flex-end',
                pr: 4,
              }}
            >
              {[0, 1, 2].map((i) => (
                <IconButton
                  sx={{ p: 0.25, m: 0 }}
                  onClick={() => setCurrentSlide(i)}
                >
                  {currentSlide !== i ? (
                    <CircleOutlined color="white" sx={{ color: 'white' }} />
                  ) : (
                    <Circle color="white" sx={{ color: 'white' }} />
                  )}
                </IconButton>
              ))}
            </Grid>
          </Grid>
        </Grid>
        {LandingInfo()}
        {Footer()}
      </Grid>
    </div>
  )

  function LandingInfo() {
    return (
      <Grid container sx={{}}>
        <Grid
          item
          sx={{ justifyContent: 'flex-start', flexGrow: 1, p: 4 }}
          xs={12}
          sm={6}
        >
          <Grid container justifyContent="flex-start">
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontWeight: 700,
                fontSize: '54px',
                lineHeight: '66px',
                color: '#24CEB0',
                textAlign: 'left',
              }}
            >
              EWIE
            </Typography>
          </Grid>
          <Grid container justifyContent="flex-start" sx={{ pt: 2 }}>
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontWeight: 700,
                fontSize: '38px',
                lineHeight: '50px',
                color: '#19B6E6',
                textAlign: 'left',
              }}
            >
              East West Integrative Education
            </Typography>
          </Grid>
          <Grid container justifyContent="flex-start" sx={{ pt: 2 }}>
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontWeight: 400,
                fontSize: '18px',
                lineHeight: '30px',
                color: '#6E7191',
                textAlign: 'left',
                pb: 2,
              }}
            >
              This reference database provides visual and written comparisons of
              the 2200+ year-old acupuncture tradition to the contemporary
              myofascial pain syndrome tradition. These comparisons include
              their anatomic, clinical and physiologic information for the most
              common, clinically important trigger points and acupuncture
              points, as defined by each tradition.{' '}
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontWeight: 400,
                fontSize: '18px',
                lineHeight: '30px',
                color: '#6E7191',
                textAlign: 'left',
                pb: 2,
              }}
            >
              This reference database also provides guidance on locating these
              trigger and acupuncture points as well as guidance regarding
              direction and depth of needling at these points derived from the
              acupuncture tradition, which also has relevance to dry needling of
              trigger points.
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent="flex-start"
            sx={{ pt: 4 }}
            spacing={2}
          >
            <Grid item>
              <Button
                onClick={() => navigate('/overview')}
                variant="contained"
                sx={{
                  color: 'white',
                  fontFamily: 'Jockey One',
                  fontSize: '28px',
                  textTransform: 'none',
                  bgcolor: '#24CEB0',
                  pl: 4,
                  pr: 4,
                }}
              >
                Explore Content
              </Button>
            </Grid>
            <Grid item>
              <a
                href="https://www.ewietraining.com/"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    fontFamily: 'Jockey One',
                    fontSize: '28px',
                    textTransform: 'none',

                    pl: 4,
                    pr: 4,
                  }}
                >
                  EWIE Lecture Series
                </Button>
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{ justifyContent: 'center', flexGrow: 1 }}
          xs={12}
          sm={6}
        >
          {MasonryImageList()}
        </Grid>
      </Grid>
    )
  }

  function Footer() {
    return (
      <Grid container sx={{ bgcolor: 'white', borderTop: 2, pt: 4 }}>
        <Grid item xs={12} md={6} lg={4}>
          <Grid container>
            <Typography>
              Presented By East West Integrative Education
            </Typography>
          </Grid>
          <img src={'/EWEILogo.jpeg'} className="App-logo" alt="logo" />
        </Grid>
        <Grid item xs={6} md={3} lg={4} sx={{ height: '100%', pl: 2, pt: 2 }}>
          <Grid container sx={{ p: 2, pb: 1, justifyContent: 'flex-start' }}>
            <Typography>About Us</Typography>
          </Grid>
          <Grid
            container
            sx={{ p: 2, pt: 1, pb: 1, justifyContent: 'flex-start' }}
          >
            <Typography>FAQ</Typography>
          </Grid>
          <Grid
            container
            sx={{ p: 2, pt: 1, pb: 1, justifyContent: 'flex-start' }}
          >
            <Typography>Disclaimers</Typography>
          </Grid>
          <Grid
            container
            sx={{ p: 2, pt: 1, pb: 1, justifyContent: 'flex-start' }}
          >
            <Typography>Privacy Policy</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} md={3} lg={4} sx={{ height: '100%', pt: 2 }}>
          <Grid container>
            <Grid container sx={{ p: 2, pb: 0, justifyContent: 'flex-start' }}>
              <Typography sx={{ fontWeight: 600, fontSize: 18 }}>
                Contact
              </Typography>
            </Grid>
            <Grid
              container
              sx={{ p: 2, pt: 1, pb: 0, justifyContent: 'flex-start' }}
            >
              <Typography>info@ewietraining.com</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container sx={{ pt: 6, pb: 4 }}>
          <Typography>© 2021 East West Integrative Education</Typography>
        </Grid>
      </Grid>
    )
  }
}

export default Landing
