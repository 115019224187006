import '../App.css'
import React, { useEffect, useState } from 'react'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material'

function DisclaimerText({ handleCheckDisclaimer, disclaimerAgreed }) {
  return (
    <Grid content>
      <Typography sx={{ p: 1, fontWeight: 700, fontSize: '22px' }}>
        Disclaimer
      </Typography>
      <Typography sx={{ p: 1 }}>
        This APP is for informational purposes only. All content in this APP is
        copyright registered and may not be re-used without written permission
        from EastWestIntegrative Education, Inc. This APP information is not a
        substitute for medical care or required supervised training for
        acupuncture and/or dry needling certification (credentialing) and/or
        licensing, as required by state law. Every effort has been made to
        include accurate information in the APP. EastWestIntegrative makes no
        representations or warranties with respect to accuracy, applicability,
        fitness or completeness of the contents of this APP. Any issues reported
        in this product will be addressed on an ongoing basis in APP updates.
        APP users use this information at their own risk.
      </Typography>
      <Typography sx={{ p: 1 }}>
        Before receiving acupuncture and/or dry needling, patients should
        consult with their healthcare practitioners. Before adding acupuncture
        and/or dry needling technique to their practice, clinicians should also
        receive proper supervised instruction/training on how to prevent/manage
        potential complications of needling. Only single use sterilized needles
        should be used, after appropriate skin preparation (alcohol swab or
        pad), using clean technique and only by trained, credentialed and/or
        licensed practitioners. Needling should not be done on “needle phobic”
        patients or those without documented consent.
      </Typography>
      <Typography sx={{ p: 1 }}>
        Common needling side effects may include short-term muscle pain and
        local minor bruising. Fatigue and/or dizziness (rarely causing syncope,
        or faint “passing out”) can occur in about 1% of patients receiving
        needling. Other less common but serious risks include death, lung and/or
        other organ damage, blood vessel damage with bleeding (hemorrhage or
        bruising), persistent pain (soft tissue or nerve) in needled regions,
        nerve injury, nausea, headache and infection. Needles should not be
        inserted through areas of infection whether at superficial (skin) or
        deeper tissue levels, as this can worsen and/or spread infection. Limbs
        with lymphedema should not be needled due to increased risk of infection
        and long-term worsening of swelling. Dry needling should be avoided in
        pregnant or potentially pregnant patients, immunocompromised patients,
        and those with abnormal bleeding tendency (including those on blood
        thinning medications or supplements).
      </Typography>
      <Typography sx={{ p: 1 }}>
        The information on this APP is intended to provide users information
        about acupuncture and dry needling. This APP does not contain medical or
        psychological or other advice, and should not be interpreted by the user
        as offering such advice. Buying or using this APP does not make you a
        patient or client of Peter Dorsher MD, Inc or any of its affiliated
        practitioners, including but not limited to Peter Dorsher MD.
        EastWestIntegrative and Peter Dorsher MD, Inc and its affiliated
        practitioners specifically disclaim any and all personal liability, loss
        or risk incurred as a consequence of the use, either directly or
        indirectly, of any information in this APP. The company and its
        affiliated practitioners are not engaged in rendering legal, medical or
        psychological, financial or other professional services by publishing
        this APP.
      </Typography>
      <Grid container sx={{ p: 2, bgcolor: 'lightGrey' }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={disclaimerAgreed}
                value={disclaimerAgreed}
                onChange={handleCheckDisclaimer}
              />
            }
            label=" By clicking this check box, I certify that I have read, understood and
        agree to these terms and conditions of this disclaimer and I hold
        East West Integrative and Peter Dorsher MD, Inc harmless and relieve these
        two entities from any losses or damages for failure to do so."
          />
        </FormGroup>
      </Grid>
    </Grid>
  )
}

export default DisclaimerText
