import { PDFViewer } from '@react-pdf/renderer'
import {
  Grid,
  Typography,
  Button,
  Fab,
  TextField,
  ButtonGroup,
} from '@mui/material'
import Header from '../components/Header'
import useWindowDimensions from '../hooks/useWindowDimensions'
//import UserAgreement from '../UserAgreement.pdf';

function TextBook() {
  const { height, width } = useWindowDimensions()
  return (
    <>
      <Header />
      <Grid
        container
        //justify="center"
        justifyContent="start"
        sx={{ p: 2 }}
        style={
          {
            // height: '100vh',
          }
        }
      >
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 24 }}>
            APP Terminology (Listed Alphabetically)
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 18 }}>
            <strong>
              “Anatomically Corresponding” Trigger Points and Classical
              Acupuncture Points:
            </strong>{' '}
            If a “most common” trigger point region anatomic location
            (illustrated as a “X” in first edition of Travell and Simons’
            Trigger Point Manual) and a Classical acupuncture point location are
            proximate (near) to each other and influence the same muscle region,
            that defines an “anatomically corresponding” common trigger point
            and Classical acupuncture point pair
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 18 }}>
            <strong>Classical acupuncture points (acupoints):</strong> There are
            361 Classical acupuncture points that have been described by ~400
            CE, which exist on the “14 Channels”. Their positions are described
            in reference texts, as are their pain and non-pain indications.
            Points on same Channel often have overlap of their clinical
            indications but often have some differing indications/uses. The
            points are numbered sequentially along any given channel from where
            Channel originates to where it terminates, for example for the
            Bladder channel, BL-1, BL-2, BL-3, BL-4….. , BL-67.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>
            Channels (Meridians) of Acupuncture:
          </Typography>
          <img
            src="/terminologyImage1.png"
            style={{ width: '100%', height: 'auto' }}
          />
          <Typography sx={{ fontSize: 18 }}>
            There are twelve Primary acupuncture channels, on which the 361
            Classical acupuncture points exist. These are alphabetically by name
            (followed by abbreviation): Bladder (BL), Gallbladder (GB), Heart
            (HT), Kidney (KI), Large Intestine (LI), Liver (LR), Lung (LU),
            Pericardium (PC), Small Intestine (SI), Spleen (SP), Stomach (ST),
            and Triple Energizer (TE). Two of the eight Extraordinary channels,
            namely the Conception Vessel (CV) channel on the midline of the
            anterior body and the Governing Vessel (GV) channel on the midline
            of the posterior body, are considered with the twelve Primary
            channels as they have their own named Classical acupuncture points
            and their clinical importance. Thus, the “14 Channels” are usually
            represented on acupuncture channel schematics. These channels join
            functionally interconnected acupuncture points, and are believed to
            have been developed by observations of spread of needle sensation
            during acupuncture needling in clinical practice that joined
            acupuncture points with similar therapeutic properties.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 18 }}>
            <strong>“Cun” units of distance measure:</strong> The cun system is
            a proportional measurement system of the human body, used to aid the
            clinician to find the approximate location of any given acupuncture
            point. Points are located relative to anatomic landmarks, such as
            the transverse elbow crease, styloid process of the radius, greater
            trochanter of the hip, etc. and then using the cun measurement
            system for points’ distances from the anatomic landmark along their
            respective Channels. For example, acupuncture point PC-6 is located
            on the flexor surface of the forearm, 2 cun above (proximal to) the
            palmar wrist crease, on the Pericardium Channel, between the tendons
            of the palmaris longus and flexor carpi radialis tendons.
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            &nbsp;&nbsp;&nbsp;&nbsp; The cun size is proportional to the body
            size- 1 cun units on a 7 foot tall basketball player is larger than
            on a 5 foot tall gymnast. This cun system then facilitates
            acupuncture point localization no matter whether the patient is a
            child or adult, short or tall, overweight or thin. Descriptions of
            Classical acupuncture point locations and depths/directions of
            needling at those locations can be found in reference acupuncture
            texts such as those by Deadman, Shanghai College of Traditional
            Medicine, Wiseman and Ellis, and Eachou Chen as well as online at
            websites such as Acupuncture.com or YinYangHouse.com.
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            &nbsp;&nbsp;&nbsp;&nbsp; A clinically practical (though perhaps less
            accurate) way of measuring cun distances is by using the thumb width
            (of patient) as being 1 cun, the width of the index and long fingers
            of the patient as 1.5 cun, and the width of the 4 fingers of the
            patient as 3 cun.
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            &nbsp;&nbsp;&nbsp;&nbsp; The more accurate cun definitions are:
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            &nbsp;&nbsp;&nbsp;&nbsp; Upper extremity: the distance from the
            axillary crease to the elbow (cubital) crease is defined at 9 cun,
            and the distance from the elbow crease to the volar wrist crease is
            12 cun.
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            &nbsp;&nbsp;&nbsp;&nbsp; Lower extremity: the distance from inferior
            gluteal crease to the popliteal crease is defined as 14 cun, from
            popliteal crease to tip of medial malleolus is defined as 15 cun and
            from popliteal crease to the tip of the lateral malleolus 16 cun.
            The distance from the tip of the greater trochanter of the hip to
            the popliteal crease is defined as 19 cun, and the patella (kneecap)
            length 2 cun.
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            &nbsp;&nbsp;&nbsp;&nbsp; Abdomen/Chest: the distance from the
            umbilicus to the top of the pubic symphysis is defined as 5 cun, and
            distance from xiphisternal junction to the umbilicus as 8 cun. The
            distance between the nipples is defined as 8 cun, and from the
            anterior midline of chest to the tip of the acromion process as 8
            cun. The distance between the medial borders of the scapula is
            defined as 6 cun.
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            &nbsp;&nbsp;&nbsp;&nbsp; Head/Neck: The distance between the angles
            of the hairline is defined as 9 cun as is the distance between the
            mastoid processes, the distance between the anterior and posterior
            headlines as 12 cun, and 3 cun between the glabella and anterior
            hairline as well as from posterior hairline to inferior aspect of C7
            spinous process.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 18 }}>
            <strong>Myofascial: </strong> “myo” refers to muscle and “fascial”
            refers to connective tissue. Note all but 2 trigger points
            illustrated and described in the first edition of the Trigger Point
            Manual are muscular, the exceptions being the supraspinatus tendon
            and the lateral collateral ligament of the knee.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 18 }}>
            <strong>Needling direction: </strong> all direction are relative to
            skin surface, with perpendicular needle insertion being at right
            angle (90 degrees), transverse insertion roughly parallel to the
            skin in subcutaneous tissue, oblique insertion ~45 degree angle
            between needle and skin surface. Deadman also defines perpendicular
            oblique insertion to be at ~70 degrees relative to skin surface (~
            halfway between oblique and perpendicular insertion), and transverse
            oblique insertion at ~20 degrees angle between needle and skin
            surface (~halfway between oblique and transverse insertion).
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>
            Referred-Pain to Acupuncture Channel Distributions Comparisons (for
            Anatomically Corresponding Common Trigger Points and Classical
            Acupuncture Points):
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 18 }}>
            &nbsp;&nbsp;&nbsp;&nbsp; “Excellent” radiation correlation was
            defined as being present when a common trigger point’s local and
            distant referred pain pattern distribution followed its anatomically
            corresponding Classical acupuncture point’s channel distribution
            nearly almost completely (roughly 75%-100% overlap).
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            &nbsp;&nbsp;&nbsp;&nbsp; “Good” correlation was defined as present
            when the trigger point’s local and most of its distant referred pain
            distribution followed its anatomically corresponding Classical
            acupuncture point’s channel distribution, though a minor part of the
            distant referred pain pattern differed (roughly 50-75% overlap).
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            &nbsp;&nbsp;&nbsp;&nbsp; “Fair” correlation was defined as present
            when a trigger point’s local referred pain pattern distribution
            followed its anatomically corresponding Classical acupuncture
            point’s channel distribution, but most of the distant referred pain
            differed (roughly 25-50% overlap).
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            &nbsp;&nbsp;&nbsp;&nbsp; “Poor” correlation was defined as being
            present when a trigger point’s referred pain distribution differed
            almost entirely from its anatomically corresponding Classical
            acupuncture point’s channel distribution (roughly 10-25% overlap).
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            &nbsp;&nbsp;&nbsp;&nbsp; “None” correlation was defined when there
            was no relation of a trigger point’s referred pain pattern
            distribution to the distribution of its anatomically corresponding
            acupuncture point’s meridian.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography sx={{ fontSize: 18 }}>
            <strong>Trigger Point: </strong> An{' '}
            <strong>
              <em>active</em>
            </strong>{' '}
            (causing symptomatic pain) myofascial trigger point is a focal area
            of marked tenderness in a taut band of fibers in the affected muscle
            or its fascia, that refers a pattern of pain specific to that
            muscle, at rest and/or with use. Other diagnostic signs of a trigger
            point include a local “twitch” (local muscle fiber contraction)
            response with needling the trigger point or snapping palpation, and
            reproduction of patient’s pain symptoms by manual compression or
            needling of the trigger point. A{' '}
            <strong>
              <em>latent</em>
            </strong>{' '}
            trigger point does not cause spontaneous pain (asymptomatic re pain)
            but is tender with palpation and causes stiffness and/or restriction
            of movement of that muscle or a joint it traverses.
          </Typography>
        </Grid>

        {/* <object data="/terminology.pdf" width="100%" height="100%"></object> */}
        {/* {width < 450 && (
          <object data="/terminology.pdf" width="100%" height="100%"></object>
        )}
        <iframe src="/terminology.pdf" width="100%" height="100%"></iframe> */}
      </Grid>
    </>
  )
}

export default TextBook
