import { useEffect } from 'react'
import { Provider, useDispatch } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
//import store from './redux/store'
import './App.css'
import router from './Router'
import theme from './styles/theme'
import { API, graphqlOperation, Hub } from 'aws-amplify'
import { Amplify, Auth, Storage } from 'aws-amplify'
import awsconfig from './aws-exports'
import { setCognitoUser, signOutCognitoUser } from './redux/slices/authSlice'
import { setSubscribeModalOpen } from './redux/slices/uiSlice'

const config = {
  ...awsconfig,
  Storage: {
    AWSS3: {
      bucket: 'acutrig-images', //REQUIRED -  Amazon S3 bucket name
      region: 'us-east-2', //OPTIONAL -  Amazon service region
    },
  },
}
Amplify.configure(config)

function App() {
  const dispatch = useDispatch()
  const listener = (data) => {
    switch (data.payload.event) {
      case 'configured':
        console.debug('the Auth module is configured')
        break
      case 'signIn':
        console.debug('user signed in')
        dispatch(
          setCognitoUser({
            ...data.payload.data.attributes,
            jwt: data.payload.data.signInUserSession.accessToken.jwtToken,
          })
        )
        break
      case 'signIn_failure':
        console.error('user sign in failed')
        break
      case 'signUp':
        console.debug('user signed up')
        break
      case 'signUp_failure':
        console.error('user sign up failed')
        break
      case 'confirmSignUp':
        console.debug('user confirmation successful')
        break
      case 'completeNewPassword_failure':
        console.error('user did not complete new password flow')
        break
      case 'autoSignIn':
        console.debug('auto sign in successful', data.payload)
        dispatch(
          setCognitoUser({
            ...data.payload.data.attributes,
            jwt: data.payload.data.signInUserSession.accessToken.jwtToken,
          })
        )
        break
      case 'autoSignIn_failure':
        console.error('auto sign in failed')
        break
      case 'forgotPassword':
        console.debug('password recovery initiated')
        break
      case 'forgotPassword_failure':
        console.error('password recovery failed')
        break
      case 'forgotPasswordSubmit':
        console.debug('password confirmation successful')
        break
      case 'forgotPasswordSubmit_failure':
        console.error('password confirmation failed')
        break
      case 'tokenRefresh':
        console.debug('token refresh succeeded')
        break
      case 'tokenRefresh_failure':
        console.error('token refresh failed')
        break
      case 'cognitoHostedUI':
        console.debug('Cognito Hosted UI sign in successful')
        break
      case 'cognitoHostedUI_failure':
        console.error('Cognito Hosted UI sign in failed')
        break
      case 'customOAuthState':
        console.debug('custom state returned from CognitoHosted UI')
        break
      case 'customState_failure':
        console.error('custom state failure')
        break
      case 'parsingCallbackUrl':
        console.debug('Cognito Hosted UI OAuth url parsing initiated')
        break
      case 'userDeleted':
        console.debug('user deletion successful')
        break
      case 'signOut':
        console.debug('user signed out')
        dispatch(signOutCognitoUser())
        break
      case 'updateUserAttributes':
        if (data.payload.data['custom:paid'].isUpdated) {
          dispatch(setSubscribeModalOpen(false))
        }
        break
      default:
        console.error('Unknown Auth Hub Event Type: ', data.payload)
        break
    }
  }
  function listenToAuthEvents() {
    console.debug('listen to auth events')
    Hub.listen('auth', listener)
  }
  async function checkCurrentUser() {
    try {
      let user = await Auth.currentAuthenticatedUser()

      const { attributes, signInUserSession } = user
      dispatch(
        setCognitoUser({
          ...attributes,
          jwt: signInUserSession.accessToken.jwtToken,
        })
      )
      console.debug('Checking Here: attributes: ', user)
    } catch (error) {
      console.warn('Error Getting Current User: ', error)
    }
  }
  useEffect(() => {
    console.debug('Main App Use Effect')
    checkCurrentUser()
    listenToAuthEvents()
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  )
}

export default App
