import { createSlice } from '@reduxjs/toolkit'

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    pointTabIndex: 0,
    authModalOpen: false,
    errorAlertOpen: false,
    subscribeModalOpen: false,
    errorAlertMessage: '',
    viewPointId: null,
    headerSearch: {},
    savedUrls: {},
    subscription: {},
  },
  reducers: {
    setPointTabIndex: (state, action) => {
      state.pointTabIndex = action.payload
    },
    setHeaderSearchData: (state, action) => {
      state.headerSearch[action.payload.field] = action.payload.value
    },
    closeErrorAlert: (state, action) => {
      state.errorAlertMessage = ''
      state.errorAlertOpen = false
    },
    setErrorAlert: (state, action) => {
      state.errorAlertMessage = action.payload
      state.errorAlertOpen = true
    },
    setAuthModalOpen: (state, action) => {
      state.authModalOpen = action.payload
    },
    setSubscribeModalOpen: (state, action) => {
      state.subscribeModalOpen = action.payload
    },
    setMuscleViewModalOpen(state, action) {
      state.muscleViewModalOpen = action.payload
    },
    setSubscription(state, action) {
      state.subscription = action.payload
    },
    setSelectedMuscleModalImage(state, action) {
      state.selectedMuscleModalImage = action.payload.image
      state.selectedMuscleModalName = action.payload.name
      state.selectedMuscleModalTriggerPoints = action.payload.triggerPoints
    },
    updateSavedUrls(state, action) {
      if (state.savedUrls[action.payload.imagePath]) {
        state.savedUrls[action.payload.imagePath] = action.payload.url
      } else {
        state.savedUrls = {
          ...state.savedUrls,
          [action.payload.imagePath]: action.payload.url,
        }
      }
    },
  },
})

export const {
  setPointTabIndex,
  closeErrorAlert,
  setErrorAlert,
  setAuthModalOpen,
  setPointId,
  setHeaderSearchData,
  updateSavedUrls,
  setMuscleViewModalOpen,
  setSelectedMuscleModalImage,
  setSubscribeModalOpen,
  setSubscription,
} = uiSlice.actions
export default uiSlice.reducer
