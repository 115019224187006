import { createSlice } from '@reduxjs/toolkit'

/*
title: String!
	inPerson: Boolean
	virtual: Boolean
	instantBooking: Boolean
	instantBookingMinimum: Int
	description: String
	photos: [String]
	coverPhotoIndex: Int
	trainingType: String
	defaultPrice: Int
	indoorLocation: LocationInput!
	outdoorLocation: LocationInput!
	commute: CommuteInput
	equipment: [EquipmentInput]
	trainingStyle: TrainingStyleInput
	pricing: [PricingInput]
	isPaused: Boolean
	isSearchable: Boolean
	availability: AvailabilityInput
*/

const formsSlice = createSlice({
  name: 'forms',
  initialState: {
    userProfileModal: {
      initialLoad: true,
      id: '',
      username: '',
      pronouns: '',
      profilePhotoUrl: '',
      homeLocation: '',
      about: '',
      infoForTrainers: '',
      fitnessGoals: [],
      workoutPreferences: [],
      trainingTypes: ['in person', 'virtual'],
      trainingTimePreferences: [],
      trainingDayPreferences: [],
      _version: 0,
      userStatsId: '',
      gender: '',
      profilePicture: 'https://via.placeholder.com/150',
      uploadedImage: null,
    },
    createBookingTime: {
      month: '2',
      date: '8',
      year: '2023',
      time: '8',
      amPm: 'AM',
      zone: 'PST',
      duration: '60',
    },
    createBookingForm: {
      status: 'pending',
      price: 0,
      startTime: 'start-time',
      endTime: 'end-time',
      userBookingsId: '95da19a8-b876-4d50-aaef-e06d0f7416b7',
      trainerBookingsId: 'b6dc0151-a2ba-4aca-8660-3cc6f0d1f834',
      bookingSessionId: 'fd8da131-157d-4bcb-9aaf-d740fb395b45',
    },
    createSessionForm: {
      title: 'Session Title',
      description: 'Session Description',
      inPerson: false,
      virtual: false,
      maxTimeAfterStart: 0,
      category: 'Category',
      city: 'City',
      state: 'State',
      defaultPrice: 0,
      commuteToMember: false,
      maxCommuteDistance: 0,
      commuteFlatFee: 0,
      cheerleader: 0,
      drillSargeant: 0,
      professor: 0,
      workoutBuddy: 0,
      //Update this for typo in appsync schema
      thrityMinsPrice: 0,
      thirtyMinsPrice: 0,
      sixtyMinsPrice: 0,
      ninetyMinsPrice: 0,
      oneTwentyMinsPrice: 0,
      coverPhotoIndex: 0,
      sessionPhotos: ['https://via.placeholder.com/150'],
      instantBookingEnabled: false,
      minTimeBeforeInstantBooking: 24,
      indoorLocation: {
        name: 'testname remove!',
        address: 'test',
        isIndoor: true,
        directions: 'test',
        lng: -118.323414,
        lat: 34.100493,
        // city: '',
        // state: '',
        // zip: '',
        // lat: 0,
        // lng: 0,
      },
      outdoorLocation: {
        name: 'Hollywood Hills',
        address: '12345 Hollywood Blvd',
        isIndoor: false,
        directions: 'Meet by the blue sign on the side of the road',
        lng: -118.323414,
        lat: 34.100493,
        // city: '',
        // state: '',
        // zip: '',
        // lat: 0,
        // lng: 0,
      },
      commute: {
        commuteEnabled: false,
        maxDistance: 0,
        flatFee: 0,
      },
      equipment: [],
      paused: false,
      searchable: false,
      availability: {
        //durationsEnabled: [],
        maxSessionsPerDay: 0,
        minTimeBetweenSessions: 0,
        minTimeBeforeBooking: 0,
        isRepeat: true,
        startDate: 'default start',
        endDate: 'default end',
        daysAvailable: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
      },
    },
  },
  reducers: {
    setUploadedImage: (state, action) => {
      state.userProfileModal.uploadedImage = action.payload
    },
    setUserProfilePicture: (state, action) => {
      state.userProfileModal.profilePicture = action.payload
    },
    setUserProfileModalField: (state, action) => {
      state.userProfileModal[action.payload.field] = action.payload.value
    },
    setInitialProfileModalState: (state, action) => {
      state.userProfileModal = { ...action.payload, initialLoad: false }
    },
    setCreateSessionField: (state, action) => {
      state.createSessionForm[action.payload.field] = action.payload.value
    },
    setCreateBookingField: (state, action) => {
      state.createBookingForm[action.payload.field] = action.payload.value
    },
    setCreateBookingTime: (state, action) => {
      state.createBookingTime[action.payload.field] = action.payload.value
    },
    setSessionCommuteField: (state, action) => {
      state.createSessionForm.commute[action.payload.field] =
        action.payload.value
    },
    setSessionAvailabilityField: (state, action) => {
      state.createSessionForm.availability[action.payload.field] =
        action.payload.value
    },
    setSessionIndoorLocationField: (state, action) => {
      state.createSessionForm.indoorLocation[action.payload.field] =
        action.payload.value
    },
    setSessionOutdoorLocationField: (state, action) => {
      state.createSessionForm.outdoorLocation[action.payload.field] =
        action.payload.value
    },
  },
})

export const {
  setSessionCommuteField,
  setSessionAvailabilityField,
  setSessionIndoorLocationField,
  setSessionOutdoorLocationField,
  setUploadedImage,
  setUserProfilePicture,
  setInitialProfileModalState,
  setUserProfileModalField,
  setCreateSessionField,
  setCreateBookingField,
  setCreateBookingTime,
} = formsSlice.actions
export default formsSlice.reducer
