import React from 'react'

const CloseIcon = (params) => (
  <svg
    width="45"
    height="46"
    viewBox="0 0 45 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.1317 12.247L22.9039 20.4748L14.6761 12.247L12.5548 14.3683L20.7826 22.5961L12.5548 30.8239L14.6761 32.9452L22.9039 24.7174L31.1317 32.9452L33.253 30.8239L25.0252 22.5961L33.253 14.3683L31.1317 12.247Z"
      fill="#8B8B8B"
    />
    <path
      d="M38.4099 7.0901C34.1903 2.87053 28.4674 0.5 22.5 0.5C16.5326 0.5 10.8097 2.87053 6.5901 7.0901C2.37053 11.3097 4.44604e-08 17.0326 0 23C-4.44604e-08 28.9674 2.37053 34.6903 6.5901 38.9099C10.8097 43.1295 16.5326 45.5 22.5 45.5C28.4674 45.5 34.1903 43.1295 38.4099 38.9099C42.6295 34.6903 45 28.9674 45 23C45 17.0326 42.6295 11.3097 38.4099 7.0901ZM22.5 42.5C11.7477 42.5 3.00004 33.7523 3.00004 23C3.00004 12.2477 11.7477 3.50004 22.5 3.50004C33.2523 3.50004 42 12.2477 42 23C42 33.7523 33.2523 42.5 22.5 42.5Z"
      fill="#8B8B8B"
    />
  </svg>
)

export default CloseIcon
