import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import SvgIcon from '@mui/material/SvgIcon'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  AccountCircleOutlined,
  Book,
  Home,
  MenuBook,
  Search,
} from '@mui/icons-material'
import {
  setAuthModalOpen,
  setMuscleViewModalOpen,
  setSelectedMuscleModalImage,
} from '../redux/slices/uiSlice'
import AuthModal from '../modals/AuthModal'
import { Auth } from 'aws-amplify'
import SubscribeModal from '../modals/SubscribeModal'
import {
  Autocomplete,
  FormControl,
  Grid,
  InputAdornment,
  InputBase,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material'
import correspondence from '../constants/correspondence.json'
import allMuscles from '../constants/allMuscles.json'
import muscleImagesSingle from '../constants/muscleImagesSingle.json'
import { muscleImages } from '../constants/muscleImages'

function SearchContainer() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [anchorElSearch, setAnchorElSearch] = React.useState(null)
  const [searchBy, setSearchBy] = React.useState('muscle')
  const muscleNames = allMuscles.muscles.map((muscle) => muscle.name)

  const handleOpenSearchMenu = (event) => {
    setAnchorElSearch(event.currentTarget)
  }

  const handleCloseSearchMenu = () => {
    setAnchorElSearch(null)
  }

  const handleChangeSearch = (event) => {
    if (event.target.value !== '' && anchorElSearch === null) {
      setAnchorElSearch(event.currentTarget)
    } else if (event.target.value === '' && anchorElSearch !== null) {
      setAnchorElSearch(null)
    }
  }

  const handleChangedValue = (event, value) => {
    if (value !== null) {
      const index = allMuscles.muscles.findIndex((x) => x.name === value)
      const muscle = allMuscles.muscles[index]
      const image = muscleImagesSingle.images[index]

      dispatch(setMuscleViewModalOpen(true))
      dispatch(
        setSelectedMuscleModalImage({
          image,
          name: muscle.name,
          triggerPoints: muscle.triggerPoints,
        })
      )
    }
    // if (value !== null) {
    //   const triggerIndex = value.split(' <-> ')[0]
    //   const accupoint = value.split(' <-> ')[1]
    //   console.debug('triggerIndex', triggerIndex)
    //   const index = triggerIndex.split('TP')[1]
    //   console.debug('accupoint', accupoint)
    //   navigate(`/point-detail/${index}`)
    // }
  }

  return (
    <Grid item sx={{ flexGrow: 1 }}>
      <Autocomplete
        id="Trigger Point Search"
        freeSolo
        onChange={(event, value) => handleChangedValue(event, value)}
        // options={correspondence.correspondence.map(
        //   (option) => option.TriggerIndex + ' <-> ' + option.Accupoint
        // )}
        sx={{ borderRadius: '16px' }}
        options={muscleNames.map((name) => name)}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              sx: { borderRadius: '16px' },
              ...params.inputProps,
            }}
            label="Search"
          />
        )}
      />
      {/* <FormControl sx={{ width: '100%', pr: 2 }} variant="outlined">
        <InputLabel htmlFor="search">Search</InputLabel>
        <OutlinedInput
          id="search"
          type={'text'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => console.debug('search')}
                edge="end"
              >
                <Search />
              </IconButton>
            </InputAdornment>
          }
          label="search"
          onChange={handleChangeSearch}
        />
        <Menu
          id="menu-appbar"
          anchorEl={anchorElSearch}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElSearch)}
          onClose={handleCloseSearchMenu}
        >
          {[1, 2, 3, 4, 5].map((setting) => (
            <MenuItem
              key={setting.name}
              onClick={
                setting.onClick ? setting.onClick : handleCloseSearchMenu
              }
            >
              <Typography textAlign="center">{setting}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </FormControl> */}
    </Grid>
  )
}
export default SearchContainer
