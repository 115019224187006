exports.muscleImages = [
  //Head Neck
  [
    'Muscles/Head/1.jpg',
    'Muscles/Head/2.jpg',
    'Muscles/Head/3.jpg',
    'Muscles/Head/4.jpg',
    'Muscles/Head/5.jpg',
    'Muscles/Head/6.jpg',
    'Muscles/Head/7.jpg',
    'Muscles/Head/8.jpg',
    'Muscles/Head/9.jpg',
    'Muscles/Head/10.jpg',
    'Muscles/Head/11.jpg',
    'Muscles/Head/12.jpg',
    'Muscles/Head/13.jpg',
    'Muscles/Head/14.jpg',
    'Muscles/Head/15.jpg',
    'Muscles/Head/16.jpg',
    'Muscles/Head/17.jpg',
  ],
  //Upback
  [
    'Muscles/Back/18t.jpg',
    'Muscles/Back/19t.jpg',
    'Muscles/Back/20t.jpg',
    'Muscles/Back/21t.jpg',
    'Muscles/Back/22t.jpg',
    'Muscles/Back/23t.jpg',
    'Muscles/Back/24t.jpg',
    'Muscles/Back/25t.jpg',
    'Muscles/Back/26t.jpg',
    'Muscles/Back/27t.jpg',
    'Muscles/Back/28t.jpg',
    'Muscles/Back/29t.jpg',
    'Muscles/Back/30t.jpg',
    'Muscles/Back/31t.jpg',
    'Muscles/Back/32t.jpg',
    'Muscles/Back/37t.jpg',
    'Muscles/Back/34t.jpg',
    'Muscles/Back/36t.jpg',
  ],
  //wrist
  [
    'Muscles/Wrist/37t.jpg',
    'Muscles/Wrist/38t.jpg',
    'Muscles/Wrist/39t.jpg',
    'Muscles/Wrist/40t.jpg',
    'Muscles/Wrist/41t.jpg',
    'Muscles/Wrist/42t.jpg',
    'Muscles/Wrist/43t.jpg',
    'Muscles/Wrist/44t.jpg',
    'Muscles/Wrist/45t.jpg',
    'Muscles/Wrist/46t.jpg',
    'Muscles/Wrist/47t.jpg',
    'Muscles/Wrist/48t.jpg',
    'Muscles/Wrist/49t.jpg',
    'Muscles/Wrist/50t.jpg',
    'Muscles/Wrist/51t.jpg',
  ],
  //abdominals
  [
    'Muscles/Thoracic/52t.jpg',
    'Muscles/Thoracic/53t.jpg',
    'Muscles/Thoracic/54t.jpg',
    'Muscles/Thoracic/55t.jpg',
    'Muscles/Thoracic/56t.jpg',
    'Muscles/Thoracic/57t.jpg',
    'Muscles/Thoracic/58t.jpg',
    'Muscles/Thoracic/59t.jpg',
    'Muscles/Thoracic/60oat.jpg',
    'Muscles/Thoracic/60opt.jpg',
    'Muscles/Thoracic/60dt.jpg',
    'Muscles/Thoracic/61t.jpg',
    'Muscles/Thoracic/62t.jpg',
  ],
  //lumbar
  [
    'Muscles/Lumbar/63t.jpg',
    'Muscles/Lumbar/65t.jpg',
    'Muscles/Lumbar/66t.jpg',
    'Muscles/Lumbar/67t.jpg',
    'Muscles/Lumbar/68t.jpg',
    'Muscles/Lumbar/69t.jpg',
    'Muscles/Lumbar/70t.jpg',
    'Muscles/Lumbar/71t.jpg',
  ],
  //thigh
  [
    'Muscles/Thigh/72t.jpg',
    'Muscles/Thigh/73t.jpg',
    'Muscles/Thigh/74t.jpg',
    'Muscles/Thigh/75t.jpg',
    'Muscles/Thigh/76t.jpg',
    'Muscles/Thigh/77t.jpg',
    'Muscles/Thigh/78t.jpg',
    'Muscles/Thigh/79at.jpg',
    'Muscles/Thigh/79gt.jpg',
    'Muscles/Thigh/80t.jpg',
    'Muscles/Thigh/81t.jpg',
  ],
  //calf
  [
    'Muscles/Calf/82t.jpg',
    'Muscles/Calf/83t.jpg',
    'Muscles/Calf/84t.jpg',
    'Muscles/Calf/85st.jpg',
    'Muscles/Calf/85pt.jpg',
    'Muscles/Calf/86t.jpg',
    'Muscles/Calf/87t.jpg',
    'Muscles/Calf/88t.jpg',
    'Muscles/Calf/89t.jpg',
    'Muscles/Calf/90t.jpg',
    'Muscles/Calf/91t.jpg',
    'Muscles/Calf/92t.jpg',
    'Muscles/Calf/93t.jpg',
    'Muscles/Calf/94t.jpg',
    'Muscles/Calf/95t.jpg',
    'Muscles/Calf/96t.jpg',
  ],
]
