import { Home } from '@mui/icons-material'
import { Button, Grid, Typography } from '@mui/material'
import { useNavigate, useRouteError } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'

export default function ErrorPage() {
  const routeError = useRouteError()
  const navigate = useNavigate()
  return (
    <div className="App">
      <Header />
      <Grid
        container
        spacing={2}
        sx={{ p: 2, height: '70vh', alignItems: 'center' }}
      >
        <Grid item xs={12}>
          <Typography sx={{ fontSize: 36 }}>
            {' '}
            Sorry, we couldn't find that page
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button onClick={() => navigate('/home')} variant="contained">
            <Home sx={{ pr: 1 }} />
            <Typography sx={{ fontSize: 24 }}>Return Home</Typography>
          </Button>
        </Grid>
      </Grid>
      <Footer />
    </div>
  )
}
