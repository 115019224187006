exports.CreateSetupIntentUrl =
  'https://h7kuzkvimnqlxguzh5swbtjppm0aygbo.lambda-url.us-east-2.on.aws/'

exports.CreatePaymentIntentUrl =
  'https://duajjsuifzwyj2lpgzyjm4voqm0xbbnj.lambda-url.us-east-2.on.aws/'

exports.GetPaymentMethodsUrl =
  'https://y7yr5hg2k2xvv6mtnjuwunffve0qnliv.lambda-url.us-east-2.on.aws/'

exports.UpdateDefaultPaymentMethodUrl =
  'https://dv5lchchmq3w2rwpy6xj52oyk40hlxns.lambda-url.us-east-2.on.aws/'

exports.SubscribeUrl =
  'https://2t2qddekpbexin6zd7mjjquf4a0usklf.lambda-url.us-east-2.on.aws/'

exports.GetCustomerSubscriptionsUrl =
  'https://zvjksmsnybopoc5mapmds52aei0vimpe.lambda-url.us-east-2.on.aws/'

exports.CreateCustomerUrl =
  'https://6ze3ptnn6lrdckucpdshskpcgy0wrtxd.lambda-url.us-east-2.on.aws/'

exports.GetCustomerSubscriptions =
  'https://nvvbg4jfhpckfnwbekdbhgubri0iixil.lambda-url.us-east-2.on.aws/'

exports.CancelCustomerSubscription =
  'https://5d6rhee5sihqnicymrrg6ff64a0zwhsn.lambda-url.us-east-2.on.aws/'
