import React from 'react'

const ForwardChevronIcon = (params) => (
  <svg
    transform={params.backwards ? 'rotate(180)' : null}
    width="100%"
    height="100%"
    viewBox="0 0 78 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.9575 58.9299L50.1475 39.6416C52.65 37.3637 52.65 33.6362 50.1475 31.3583L28.9575 12.0699"
      stroke="#8B8B8B"
      stroke-width="5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export default ForwardChevronIcon
