import { Grid, Typography } from '@mui/material'

export default function Footer() {
  return (
    <Grid container sx={{ bgcolor: 'white', borderTop: 2 }}>
      <Grid item xs={12} md={6} lg={4}>
        <Grid container>
          <Typography>Presented By East West Integrative Education</Typography>
        </Grid>
        <img src={'/EWEILogo.jpeg'} className="App-logo" alt="logo" />
      </Grid>
      <Grid item xs={6} md={3} lg={4} sx={{ height: '100%', pl: 2, pt: 2 }}>
        <Grid container sx={{ p: 2, pb: 1, justifyContent: 'flex-start' }}>
          <Typography>About Us</Typography>
        </Grid>
        <Grid
          container
          sx={{ p: 2, pt: 1, pb: 1, justifyContent: 'flex-start' }}
        >
          <Typography>FAQ</Typography>
        </Grid>
        <Grid
          container
          sx={{ p: 2, pt: 1, pb: 1, justifyContent: 'flex-start' }}
        >
          <Typography>Disclaimers</Typography>
        </Grid>
        <Grid
          container
          sx={{ p: 2, pt: 1, pb: 1, justifyContent: 'flex-start' }}
        >
          <Typography>Privacy Policy</Typography>
        </Grid>
      </Grid>
      <Grid item xs={6} md={3} lg={4} sx={{ height: '100%', pt: 2 }}>
        <Grid container>
          <Grid container sx={{ p: 2, pb: 0, justifyContent: 'flex-start' }}>
            <Typography sx={{ fontWeight: 600, fontSize: 18 }}>
              Contact
            </Typography>
          </Grid>
          <Grid
            container
            sx={{ p: 2, pt: 1, pb: 0, justifyContent: 'flex-start' }}
          >
            <Typography>support@ewie.org</Typography>
          </Grid>
          <Grid container sx={{ p: 2, pt: 1, justifyContent: 'flex-start' }}>
            <Typography>+1 (904) 716-5632</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Typography>© 2021 East West Integrative Education</Typography>
      </Grid>
    </Grid>
  )
}
