import '../App.css'
import React, { useEffect, useState } from 'react'
import { Storage } from 'aws-amplify'
import { CircularProgress, Fade, Grid, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { updateSavedUrls } from '../redux/slices/uiSlice'

function S3Image({ imagePath, nullRadius }) {
  const [image, setImage] = useState(null)
  const dispatch = useDispatch()
  const savedUrl = useSelector((state) => state.ui.savedUrls[imagePath])

  const getImageTest = async () => {
    try {
      //console.log('getImageTest: ', imagePath)
      const signedURL = await Storage.get(imagePath, { download: true })
      const url = URL.createObjectURL(signedURL.Body)
      dispatch(updateSavedUrls({ imagePath, url }))
      setImage(url)
    } catch (error) {
      console.error('error loading image: ', error)
    }
  }
  useEffect(() => {
    setImage(null)
    if (!savedUrl) {
      getImageTest()
    } else {
      setImage(savedUrl)
    }
  }, [imagePath])

  return (
    <Grid
      container
      style={{
        justifyContent: 'center',

        width: '100%',
      }}
    >
      {!image && (
        <Fade in={!image}>
          <Grid
            container
            sx={{
              justifyContent: 'center',
              width: '100%',
              backgroundColor: 'black',
              borderRadius: nullRadius ? null : '36px',
            }}
          >
            <CircularProgress
              className="Trigger-Detail-Image"
              style={{ width: '50%', height: 'auto' }}
            />
          </Grid>
        </Fade>
      )}
      {image && (
        <Fade in={image}>
          <Grid container sx={{ justifyContent: 'center' }}>
            <Grid item key={imagePath} sx={{}}>
              <img
                src={image}
                className="Trigger-Detail-Image"
                alt={imagePath}
                style={{ objectFit: 'contain', borderRadius: '36px' }}
              />
            </Grid>
          </Grid>
        </Fade>
      )}
    </Grid>
  )
}

export default S3Image
