import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import SvgIcon from '@mui/material/SvgIcon'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  AccountCircleOutlined,
  Book,
  Home,
  InfoOutlined,
  MenuBook,
  MyLocation,
  Search,
} from '@mui/icons-material'
import {
  setAuthModalOpen,
  setSubscribeModalOpen,
  setSubscription,
} from '../redux/slices/uiSlice'
import AuthModal from '../modals/AuthModal'
import { Auth } from 'aws-amplify'
import SubscribeModal from '../modals/SubscribeModal'
import {
  FormControl,
  Grid,
  InputAdornment,
  InputBase,
  InputLabel,
  OutlinedInput,
} from '@mui/material'
import SearchContainer from './SearchContainer'
import MuscleViewModal from '../modals/MuscleViewModal'
import axios from 'axios'
import HeaderDrawer from './HeaderDrawer'
import { useTheme } from '@mui/material/styles'

function Header() {
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const cognitoUser = useSelector((state) => state.auth.cognitoUser)
  const memberSignedIn = useSelector((state) => state.auth.memberSignedIn)
  const authModalOpen = useSelector((state) => state.ui.authModalOpen)
  const checkUserPaymentStatus = () => {
    if (cognitoUser?.['custom:paid'] === 'true') {
      dispatch(setSubscribeModalOpen(false))
    } else {
      dispatch(setSubscribeModalOpen(true))
    }
  }

  React.useEffect(() => {
    if (cognitoUser?.['custom:stripe_customer_id']) {
      checkUserPaymentStatus()
    }
  }, [cognitoUser])

  const dispatch = useDispatch()
  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const [anchorElUser, setAnchorElUser] = React.useState(null)

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleOpenAuthModal = () => {
    dispatch(setAuthModalOpen(true))
  }

  const handleSignOut = async () => {
    console.debug('trying to sign out')
    try {
      await Auth.signOut()
      // console.debug('success sign out')
      navigate('/')
      handleCloseUserMenu()
    } catch (error) {
      console.debug('error signing out: ', error)
    }
  }

  const pages = [
    // { name: 'Account', onClick: () => navigate('/account') },
    { name: 'Log Out', onClick: () => handleSignOut() },
  ]

  // Trigger Points Screens
  // - Trigger Points
  // - Point Details
  // - Muscle View
  // - Muscle Regions

  const currPage = useLocation().pathname

  const menuItem = (page) => {
    return (
      <MenuItem key={page.name} onClick={() => navigate(page.link)}>
        <SvgIcon
          inheritViewBox
          component={page.icon}
          sx={{ mr: 1, fontSize: 24, stroke: 'white' }}
        />
        <Typography textAlign="center">{page.name}</Typography>
      </MenuItem>
    )
  }

  return (
    <AppBar
      position="sticky"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        height: '10vh',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <AuthModal />
      <SubscribeModal />
      <MuscleViewModal />
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Grid
            container
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Grid
              item
              sx={{ flexGrow: 0.25, display: { xs: 'none', md: 'flex' } }}
            >
              <Grid container>
                <Tooltip title="About">
                  <Button
                    onClick={() => navigate('/overview')}
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                  >
                    <Grid container>
                      <Grid container>
                        <InfoOutlined
                          sx={{
                            color:
                              location.pathname === '/overview'
                                ? theme.palette.primary.main
                                : 'inherit',
                          }}
                        />
                      </Grid>
                      <Grid container>
                        <Typography
                          sx={{
                            color:
                              location.pathname === '/overview'
                                ? theme.palette.primary.main
                                : 'inherit',
                          }}
                        >
                          Overview
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
            {memberSignedIn && (
              <Grid
                item
                sx={{ flexGrow: 0.25, display: { xs: 'none', md: 'flex' } }}
              >
                <Grid container>
                  <Tooltip title="Terminology">
                    <Button
                      onClick={() => navigate('/textbook')}
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                    >
                      <Grid container>
                        <Grid container>
                          <MenuBook
                            sx={{
                              color:
                                location.pathname === '/textbook'
                                  ? theme.palette.primary.main
                                  : 'inherit',
                            }}
                          />
                        </Grid>
                        <Grid container>
                          <Typography
                            sx={{
                              color:
                                location.pathname === '/textbook'
                                  ? theme.palette.primary.main
                                  : 'inherit',
                            }}
                          >
                            Terminology
                          </Typography>
                        </Grid>
                      </Grid>
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            )}

            {memberSignedIn && (
              <Grid
                item
                sx={{ flexGrow: 0.25, display: { xs: 'none', md: 'flex' } }}
              >
                <Grid container>
                  <Tooltip title="Trigger Points">
                    <Button
                      onClick={() => navigate('/triggers')}
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                    >
                      <Grid container>
                        <Grid container>
                          <MyLocation
                            sx={{
                              color:
                                location.pathname === '/triggers'
                                  ? theme.palette.primary.main
                                  : 'inherit',
                            }}
                          />
                        </Grid>
                        <Grid container>
                          <Typography
                            sx={{
                              color:
                                location.pathname === '/triggers'
                                  ? theme.palette.primary.main
                                  : 'inherit',
                            }}
                          >
                            Trigger Points
                          </Typography>
                        </Grid>
                      </Grid>
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            )}

            <Grid
              sx={{
                flexGrow: 0.25,
                display: { xs: 'flex', md: 'none' },
              }}
            >
              <HeaderDrawer />
            </Grid>
            {memberSignedIn && <SearchContainer />}
            <Grid
              item
              sx={{ flexGrow: 0.25, display: { xs: 'none', md: 'flex' } }}
            >
              <Grid container>
                <Tooltip title="Home">
                  <Button
                    onClick={() => navigate('/home')}
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                  >
                    <Grid container>
                      <Grid container>
                        <Home
                          sx={{
                            color:
                              location.pathname === '/home'
                                ? theme.palette.primary.main
                                : 'inherit',
                          }}
                        />
                      </Grid>
                      <Grid container>
                        <Typography
                          sx={{
                            color:
                              location.pathname === '/home'
                                ? theme.palette.primary.main
                                : 'inherit',
                          }}
                        >
                          Home
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid item sx={{ flexGrow: 0.25 }}>
              <Grid container>
                <Box sx={{ flexGrow: 0 }}>
                  {memberSignedIn && (
                    <>
                      <Tooltip title="Open settings">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                          <Avatar
                            src="/static/images/avatar/2.jpg"
                            sx={{
                              border: 4,
                              borderColor: 'primary.main',
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  {!memberSignedIn && (
                    <Tooltip title="Sign In">
                      <IconButton onClick={handleOpenAuthModal}>
                        <AccountCircleOutlined />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {pages.map((page) => (
                      <MenuItem
                        key={page.name}
                        onClick={
                          page.onClick ? page.onClick : handleCloseUserMenu
                        }
                      >
                        <Typography textAlign="center">{page.name}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default Header
