import { configureStore } from '@reduxjs/toolkit'
import authReducer from './slices/authSlice'
import uiReducer from './slices/uiSlice'
import paymentsReducer from './slices/paymentsSlice'
import formsReducer from './slices/formsSlice'
//import { apiSlice } from './api/api'
//import { isRejectedWithValue } from '@reduxjs/toolkit'

const logger = (store) => (next) => (action) => {
  if (action.type.includes('api') && action.type.includes('rejected')) {
    return
  }
  console.group(action.type)
  console.info('dispatching', action)
  let result = next(action)
  console.debug('next state', store.getState())
  console.groupEnd()
  return result
}
const rtkQueryErrorLogger = (api) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  // if (isRejectedWithValue(action)) {
  //   console.warn('We got a rejected action!', action.error.message)
  //   console.warn('We got a rejected action!', action)

  //   console.warn('Rejected call details!', action.payload.data.error)
  //   api.dispatch(uiReducer.setErrorAlert(action.payload.data.error))
  //   //toast.warn({ title: 'Async error!', message: action.error.data.message })
  // }

  return next(action)
}

export const store = configureStore({
  reducer: {
    auth: authReducer,
    ui: uiReducer,
    payments: paymentsReducer,
    forms: formsReducer,
    // [apiSlice.reducerPath]: apiSlice.reducer,
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware()
  //     .concat(logger)
  //     .concat(rtkQueryErrorLogger)
  //     .concat(apiSlice.middleware),
})
export default store
