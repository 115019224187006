import { Home } from '@mui/icons-material'
import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { CancelCustomerSubscription } from '../constants/urls'
import axios from 'axios'
import { setSubscription } from '../redux/slices/uiSlice'
import { useState } from 'react'

export default function Account() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [cancelling, setCancelling] = useState(false)
  const subscription = useSelector((state) => state.ui.subscription)
  const handleCancelSubscription = async () => {
    setCancelling(true)
    try {
      // console.log('subscriptionId: ', subscription.id)
      const cancellation = await axios.post(CancelCustomerSubscription, {
        subscriptionId: subscription.id,
      })
      // console.log('cancellation: ', cancellation)

      dispatch(setSubscription(cancellation.data))
      setCancelling(false)
    } catch (error) {
      console.error(error)
      setCancelling(false)
    }
  }
  return (
    <div className="App">
      <Header />
      {/* <Grid container sx={{ p: 2, height: '70vh', alignItems: 'start' }}>
        <Grid item xs={12} sx={{ mt: 8 }}>
          {!subscription.cancel_at_period_end && (
            <Typography sx={{ fontSize: 24 }}>
              {' '}
              Your subscription is {subscription.status} and will be billed on{' '}
              {dayjs
                .unix(subscription.current_period_end)
                .format('MMMM DD, YYYY')}
            </Typography>
          )}
          {subscription.status === 'active' &&
            !subscription.cancel_at_period_end && (
              <Grid item xs={12} sx={{ pt: 2 }}>
                <Button
                  onClick={handleCancelSubscription}
                  color="error"
                  variant="outlined"
                  disabled={cancelling}
                >
                  {cancelling && (
                    <CircularProgress
                      size={18}
                      sx={{ color: 'error.main', mr: 1 }}
                    />
                  )}
                  <Typography sx={{ fontSize: 18 }}>
                    Cancel Subscription?
                  </Typography>
                </Button>
              </Grid>
            )}
          {subscription.cancel_at_period_end && (
            <Grid item xs={12} sx={{ pt: 2 }}>
              <Typography sx={{ fontSize: 24 }}>
                Your subscription was cancelled. You will have access to the
                application until:{' '}
                {dayjs.unix(subscription.cancel_at).format('MMMM DD, YYYY')}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid> */}
      <Footer />
    </div>
  )
}
