import * as React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import S3Image from './S3Image'
import { useSelector } from 'react-redux'
import captions from '../constants/captions.json'

export default function DetailsContainer(props) {
  const { data, page, id } = props
  const pointTabIndex = useSelector((state) => state.ui.pointTabIndex)

  return (
    <Grid
      container
      sx={{
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexGrow: 1,
        height: '80vh',
        bgcolor: 'black',
      }}
    >
      <Grid
        container
        sx={{
          bgcolor: 'black',
          flexDirection: 'row',
          justifyContent: 'space-between',

          maxHeight: '50vh',
        }}
      >
        <S3Image imagePath={data[pointTabIndex].image} />
      </Grid>

      <Grid
        container
        sx={{
          maxHeight: '30vh',
          bgcolor: 'white',
          overflow: 'auto',
          alignItems: 'center',
          height: '100%',
          alignItems: 'center',
          p: 4,
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            fontFamily: 'Merriweather',
            lineHeight: '36px',
          }}
        >
          {captions.captions[id][pointTabIndex]}
        </Typography>
      </Grid>
    </Grid>
  )
}
