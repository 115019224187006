import { createTheme, experimental_sx as sx } from '@mui/material/styles'
import { green, purple } from '@mui/material/colors'

/*
.palette
.typography
.spacing
.breakpoints
.zIndex
.transitions
.components
*/
const theme = createTheme({
  typography: {
    fontFamily: [
      'Inter',
      'sans-serif',
      'Merriweather',
      'serif',
      'Jockey One',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#00C7C7',
      light: ' #99e9e9',
    },
    secondary: {
      main: '#24CEB0',
      contrastText: 'white',
    },
    gunMetal: {
      main: '#2B303E',
      light: 'rgba(205, 210, 213, 1)',
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
        disableElevation: true,
      },
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: '40px',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          backgroundColor: 'white',
          color: 'gunMetal.main',
        },
      },
    },
    MuiGrid: {
      // todo add in variants for spacing etc container vs item
      defaultProps: {
        // The props to change the default for.
      },
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    },
  },
})

export default theme
