import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  setMuscleViewModalOpen,
  setPointTabIndex,
  setSelectedMuscleModalImage,
} from '../redux/slices/uiSlice'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '../assets/icons/CloseIcon'
import S3Image from '../components/S3Image'
import { useNavigate } from 'react-router-dom'
import captions from '../constants/captions.json'
import correspondence from '../constants/correspondence.json'
import { useState } from 'react'
import BottomNav from '../components/BottomNav'
import BackChevronIvon from '../assets/icons/BackChevronIcon'

const imageToRegionName = (image) => {
  if (!image) return null
  if (image.includes('/Head/')) {
    return 'Head/Neck'
  } else if (image.includes('/Back/')) {
    return 'Arm/Back'
  } else if (image.includes('/Wrist/')) {
    return 'Wrist'
  } else if (image.includes('/Thoracic/')) {
    return 'Chest'
  } else if (image.includes('/Lumbar/')) {
    return 'Lumbar'
  } else if (image.includes('/Thigh/')) {
    return 'Thigh'
  } else if (image.includes('/Calf/')) {
    return 'Calf/Foot'
  } else {
    return null
  }
}

export default function MuscleViewModal() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const open = useSelector((state) => state.ui.muscleViewModalOpen)
  const selectedMuscleModalImage = useSelector(
    (state) => state.ui.selectedMuscleModalImage
  )
  const selectedMuscleModalName = useSelector(
    (state) => state.ui.selectedMuscleModalName
  )
  const selectedMuscleModalTriggerPoints = useSelector(
    (state) => state.ui.selectedMuscleModalTriggerPoints
  )
  const pointTabIndex = useSelector((state) => state.ui.pointTabIndex)
  const [selectedTriggerPoint, setSelectedTriggerPoint] = useState(null)
  const [curPage, setCurPage] = useState(0)

  const handleClose = () => {
    setSelectedTriggerPoint(null)
    setCurPage(0)
    dispatch(setPointTabIndex(0))
    dispatch(
      setSelectedMuscleModalImage({
        image: null,
        name: null,
        triggerPoints: null,
      })
    )
    dispatch(setMuscleViewModalOpen(false))
  }

  const handleGoToPointDetail = (triggerPoint) => {
    //navigate(`/point-detail/${id}`)
    setSelectedTriggerPoint(triggerPoint)
    setCurPage(1)
  }

  const DetailsScreen = () => {
    return (
      <>
        <Grid
          container
          sx={{
            flexDirection: 'column',
            justifyContent: 'space-between',
            flexGrow: 1,
          }}
        >
          <Grid
            container
            sx={{
              bgcolor: 'black',
              borderRadius: '36px',

              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <S3Image
              imagePath={`TriggerPoints/${selectedTriggerPoint.triggerPoint}/${
                pointTabIndex + 1
              }.jpg`}
            />
          </Grid>

          <Grid
            container
            sx={{
              bgcolor: 'white',
              overflow: 'auto',
              alignItems: 'center',
              height: '100%',
              alignItems: 'center',
              p: 1,
              pl: 2,
              pr: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: '16px',
                fontFamily: 'Merriweather',
                lineHeight: '36px',
              }}
            >
              {captions.captions[selectedTriggerPoint.id][pointTabIndex]}
            </Typography>
          </Grid>
        </Grid>
      </>
    )
  }

  const MuscleViewPage = () => {
    return (
      <>
        <Grid container sx={{ borderRadius: '36px', bgcolor: 'black', p: 2 }}>
          <S3Image imagePath={selectedMuscleModalImage} />
        </Grid>

        <Grid container>
          {selectedMuscleModalTriggerPoints &&
            selectedMuscleModalTriggerPoints.map((triggerPoint, index) => (
              <Grid item key={index} sx={{ p: 2 }}>
                <Button
                  sx={{
                    backgroundColor: 'transparent',
                    color: 'rgba(0, 0, 0, 0.5)',
                    border: '2.5px solid #24CEB0',
                    borderRadius: '28px',
                    height: '50px',
                    width: '160px',
                    fontWeight: 300,
                    fontSize: ' 32px',
                    ':hover': {
                      backgroundColor: '#24CEB0',
                      color: '#000000',
                    },
                  }}
                  onClick={() => handleGoToPointDetail(triggerPoint)}
                >
                  <Typography variant="h5" sx={{ p: 2 }}>
                    {triggerPoint.triggerPoint}
                  </Typography>
                </Button>
              </Grid>
            ))}
        </Grid>
      </>
    )
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      maxWidth="lg"
      PaperProps={{
        sx: {
          minWidth: '50%',
          overflow: 'hidden',
          //width: '100%',
          //maxWidth: '666px !important',
          //height: '100%',
          //maxHeight: '777px !important',
          alignItems: 'center',
          borderRadius: fullScreen ? 0 : '16px',
        },
      }}
    >
      <Grid
        container
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          p: 2,
          pl: fullScreen ? 2 : 6,
          pr: fullScreen ? 2 : 6,
          pb: 0,
          mb: 0,
        }}
      >
        <Grid item xs={3}>
          <Grid container>
            {curPage > 0 && (
              <IconButton onClick={() => setCurPage(0)}>
                <BackChevronIvon />
              </IconButton>
            )}
            {curPage === 0 && (
              <IconButton onClick={() => setCurPage(0)}></IconButton>
            )}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container sx={{ textAlign: 'center' }}>
            {curPage === 0 && (
              <Grid item>
                <Grid container>
                  <Typography
                    sx={{
                      p: 2,
                      pb: 0,
                      pt: 0,
                      fontWeight: 700,
                      fontSize: fullScreen ? '28px' : '54px',
                    }}
                  >
                    {selectedMuscleModalName}
                  </Typography>
                </Grid>
                <Grid container>
                  <Typography
                    sx={{
                      p: 2,
                      pb: 0,
                      pt: 0,
                      fontWeight: 400,
                      fontSize: '16px',
                      fontFamily: 'Merriweather',
                    }}
                  >
                    {selectedMuscleModalImage
                      ? imageToRegionName(selectedMuscleModalImage)
                      : null}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {curPage > 0 && (
              <Typography
                sx={{
                  p: 2,
                  pb: 0,
                  pt: 0,
                  fontWeight: 700,
                  fontSize: fullScreen ? '28px' : '54px',
                }}
              >
                {selectedTriggerPoint.triggerPoint} ={' '}
                {
                  correspondence.correspondence[selectedTriggerPoint.id][
                    'Accupoint'
                  ]
                }
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      <DialogContent>
        {curPage === 0 && <MuscleViewPage />}
        {curPage === 1 && selectedTriggerPoint && <DetailsScreen />}
      </DialogContent>
      {curPage > 0 && (
        <DialogActions sx={{ width: '100%' }}>
          <BottomNav />
        </DialogActions>
      )}
    </Dialog>
  )
}
