import React from 'react'

const BackChevronIvon = (params) => (
  <svg
    width="17"
    height="33"
    viewBox="0 0 17 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.125 31.35L2.90001 19.125C1.45626 17.6813 1.45626 15.3188 2.90001 13.875L15.125 1.65002"
      stroke="#8B8B8B"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export default BackChevronIvon
