import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'
import { Fade, IconButton, Toolbar } from '@mui/material'
import {
  Cancel,
  Close,
  Home,
  InfoOutlined,
  Menu,
  MenuBook,
  MyLocation,
} from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

export default function HeaderDrawer() {
  const [open, setOpen] = React.useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()

  const toggleDrawer = (open) => (event) => {
    // if (
    //   event.type === 'keydown' &&
    //   (event.key === 'Tab' || event.key === 'Shift')
    // ) {
    //   return
    // }

    setOpen(open)
  }

  const pages = [
    {
      name: 'Home',
      path: '/',
      icon: <Home />,
    },
    {
      name: 'Trigger Points',
      path: '/triggers',
      icon: <MyLocation />,
    },
    {
      name: 'Terminology',
      path: '/textbook',
      icon: <MenuBook />,
    },
    {
      name: 'About',
      path: '/overview',
      icon: <InfoOutlined />,
    },
  ]

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {pages.map((page, index) => (
          <ListItem key={page.name} disablePadding>
            <ListItemButton color="primary" onClick={() => navigate(page.path)}>
              <ListItemIcon
                sx={{
                  color:
                    location.pathname === page.path
                      ? theme.palette.primary.main
                      : 'inherit',
                }}
              >
                {page.icon}
              </ListItemIcon>
              <ListItemText
                sx={{
                  color:
                    location.pathname === page.path
                      ? theme.palette.primary.main
                      : 'inherit',
                }}
                primary={page.name}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )

  return (
    <div style={{ bgcolor: 'red' }}>
      {!open && (
        <Fade in={!open}>
          <IconButton onClick={toggleDrawer(true)}>
            <Menu />
          </IconButton>
        </Fade>
      )}
      {open && (
        <Fade in={open}>
          <IconButton onClick={toggleDrawer(false)}>
            <Close />
          </IconButton>
        </Fade>
      )}

      <Drawer
        sx={{
          width: 240,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: 240,
            boxSizing: 'border-box',
          },
        }}
        anchor={'left'}
        open={open}
        onClose={toggleDrawer(false)}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto', pt: 8 }}>{list('left')}</Box>
      </Drawer>
    </div>
  )
}
