import '../App.css'
import React, { useEffect, useState } from 'react'
import logo from '../logo.svg'
import Header from '../components/Header'
import { Storage } from 'aws-amplify'
import S3Image from '../components/S3Image'
import {
  Card,
  CardActionArea,
  CardContent,
  Fab,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { Circle, CircleOutlined } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function Home() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return (
    <div className="App">
      <Header />
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={6}>
          <Card>
            <CardActionArea onClick={() => navigate('/triggers')}>
              <CardContent>
                <Typography>Images/Data</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardActionArea onClick={() => navigate('/overview')}>
              <CardContent>
                <Typography>Overview</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardActionArea onClick={() => navigate('/textbook')}>
              <CardContent>
                <Typography>Terminology</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default Home
