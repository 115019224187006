// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useState, useCallback, useEffect } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, Typography } from '@mui/material'
import {
  GetCustomerSubscriptions,
  SubscribeUrl,
  UpdateDefaultPaymentMethodUrl,
} from '../constants/urls'
import { setSubscribeModalOpen, setSubscription } from '../redux/slices/uiSlice'
import { Auth } from 'aws-amplify'

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const StripeForm = ({ clientSecret }) => {
  const stripe = useStripe()
  const elements = useElements()
  const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault()
      if (!stripe || !elements) {
        return
      }

      setIsLoading(true)

      const path = window.location.pathname
      let redirectUrl = ''

      try {
        const res = await stripe.confirmPayment({
          elements,
          redirect: 'if_required',
          // confirmParams: {
          // 	return_url: `${window.location.protocol}//${window.location.host}/dashboard/${redirectUrl}`,
          // },
        })
      } catch (error) {
        console.debug('error: ', error)
        if (error.type === 'card_error' || error.type === 'validation_error') {
          setMessage(error.message)
        } else {
          setMessage('An unexpected error occurred.')
        }
      }
      setIsLoading(false)
    },
    [origin, elements, stripe]
  )

  const updateUserPaidStatus = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      const result = await Auth.updateUserAttributes(user, {
        'custom:paid': 'true',
      })
    } catch (error) {
      console.error('error: ', error)
    }
  }

  useEffect(() => {
    if (!stripe) {
      return
    }

    if (!clientSecret) {
      return
    }

    if (isLoading) {
      setMessage('Processing...')
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!')
          // Dispatch that this user is a paid subscriber now
          updateUserPaidStatus()
          break
        case 'processing':
          setMessage('Your payment is processing.')
          break
        case 'requires_payment_method':
          setMessage('')
          break
        default:
          setMessage('Something went wrong.')
          break
      }
    })
  }, [stripe, isLoading])

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <Button
          onClick={handleSubmit}
          disabled={isLoading}
          variant="contained"
          color="secondary"
          sx={{
            width: '100%',
            borderRadius: '8px',
            textTransform: 'none',
            mt: 2,
          }}
        >
          {isLoading && <CircularProgress />}
          <Typography sx={{ fontWeight: 'bold' }}>Submit</Typography>
        </Button>
      </form>
      {message && <div>{message}</div>}
    </>
  )
}

export default StripeForm
